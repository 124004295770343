import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Chip, Divider, IconButton, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { MyContext } from "../context/context";
import {
  Grid,
  PageNavbar,
  ProductCard,
  ProductCardContent,
  ProductCardDescription,
  ProductCardMedia,
  ProductCardSubTitle,
  ProductCardTitle,
  SearchBar,
  SearchInput,
  SeeAllProducts,
} from "../style/global.style";
import {
  ErrorMessageWrap,
  ErrorWrapTwo,
  ProductDetailsWrap,
  ProductFLexChip,
  ProductItem,
  ProductTitles,
  ProductViewWrap,
  Section,
} from "../style/page.style";
import { isEmpty, lowerCase } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import { NoProductFound } from "../components/Image";

const ChocolatierHome = () => {
  const navigate = useNavigate();
  const { chocolatierProductList } = useContext(MyContext);

  const chocolatierProduct = chocolatierProductList.slice(0, 3);

  return (
    <Section className="container">
      <Typography variant="h2" data-aos="zoom-in">
        Chocolatier
      </Typography>
      <Grid item="3" gap="1">
        {chocolatierProduct.map((item, index) => {
          const { productId, productName, category, description, image } = item;
          return (
            <Link
              key={index}
              data-aos="flip-left"
              to={`/product/chocolatier/view?productId=${productId}`}
            >
              <ProductCard variant="outlined">
                <ProductCardMedia
                  component="img"
                  image={image}
                  sx={{
                    backgroundColor: "white",
                  }}
                />
                <ProductCardContent>
                  <ProductCardTitle
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "1",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {productName}
                  </ProductCardTitle>
                  <ProductCardSubTitle>{category}</ProductCardSubTitle>
                  <ProductCardDescription
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {description}
                  </ProductCardDescription>
                </ProductCardContent>
              </ProductCard>
            </Link>
          );
        })}
      </Grid>
      <SeeAllProducts
        variant="contained"
        disableElevation
        onClick={() => navigate("/product/chocolatier")}
      >
        See All
      </SeeAllProducts>
    </Section>
  );
};

const ChocolatierProductsLists = () => {
  const { chocolatierProductList } = useContext(MyContext);
  const [searchInitVal, setSearchInitVal] = useState("");
  const searchValLen = searchInitVal.length;
  const finalProductList = chocolatierProductList.filter((i) => {
    return (
      lowerCase(i.productName).slice(0, searchValLen) ===
      lowerCase(searchInitVal)
    );
  });
  return (
    <Section className="container" pt="5">
      <PageNavbar elevation={0}>
        <SearchBar>
          <SearchInput
            placeholder="Search"
            inputProps={{ "aria-label": "search google maps" }}
            value={searchInitVal}
            onChange={(e) => setSearchInitVal(e.target.value)}
          />
          <IconButton type="button" aria-label="search">
            <SearchIcon />
          </IconButton>
          {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
        </SearchBar>
      </PageNavbar>
      {!isEmpty(finalProductList) ? (
        <>
          <Typography variant="h2" data-aos="zoom-in">
            Chocolatier
          </Typography>
          <Grid item="3" gap="1">
            {finalProductList.map((item, index) => {
              const { productId, productName, category, description, image } =
                item;
              return (
                <Link
                  key={productId}
                  data-aos="flip-left"
                  to={`view?productId=${productId}`}
                >
                  <ProductCard variant="outlined">
                    <ProductCardMedia
                      component="img"
                      image={image}
                      sx={{
                        backgroundColor: "white",
                      }}
                    />
                    <ProductCardContent>
                      <ProductCardTitle
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: "1",
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {productName}
                      </ProductCardTitle>
                      <ProductCardSubTitle>{category}</ProductCardSubTitle>
                      <ProductCardDescription
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: "2",
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {description}
                      </ProductCardDescription>
                    </ProductCardContent>
                  </ProductCard>
                </Link>
              );
            })}
          </Grid>
        </>
      ) : (
        <ErrorWrapTwo>
          <NoProductFound />
          <ErrorMessageWrap>
            <Typography variant="h3" data-aos="zoom-in">
              Oops !!
            </Typography>
            <Typography variant="h6" data-aos="zoom-in">
              Your search result was not found.
            </Typography>
          </ErrorMessageWrap>
        </ErrorWrapTwo>
      )}
    </Section>
  );
};

const ChocolatierProductView = () => {
  const { chocolatierProductList } = useContext(MyContext);
  const [param] = useSearchParams();
  const productId = param.get("productId") - 1;

  const product = chocolatierProductList[productId];

  return (
    <ProductViewWrap className="container">
      <ProductItem>
        <img src={product.image} alt="Hero" data-aos="zoom-in" />
      </ProductItem>
      <ProductItem>
        <ProductTitles>
          <Typography variant="h3" data-aos="zoom-in">
            {product.productName}
          </Typography>
          <Typography variant="h6" data-aos="zoom-in">
            {product.category}
          </Typography>
          {!isEmpty(product.subCategory) && (
            <Typography variant="subtitle1" data-aos="zoom-in">
              {product.subCategory}
            </Typography>
          )}
        </ProductTitles>
        <Divider data-aos="zoom-in" />
        <ProductDetailsWrap>
          <Typography variant="h5" data-aos="zoom-in">
            Description
          </Typography>
          <Typography variant="body1" data-aos="zoom-in">
            {product.description}
          </Typography>
        </ProductDetailsWrap>
        <ProductDetailsWrap>
          <Typography variant="h5" data-aos="zoom-in">
            Ingredient
          </Typography>
          <Typography variant="body1" data-aos="zoom-in">
            {product.ingredient}
          </Typography>
        </ProductDetailsWrap>
        <ProductDetailsWrap>
          <Typography variant="h5" data-aos="zoom-in">
            Available in
          </Typography>
          <ProductFLexChip>
            <Chip
              label={product.weight}
              color="secondary"
              variant="outlined"
              data-aos="zoom-in"
            />
          </ProductFLexChip>
        </ProductDetailsWrap>
      </ProductItem>
    </ProductViewWrap>
  );
};

export { ChocolatierHome, ChocolatierProductsLists, ChocolatierProductView };
