// import Carousel from "./Carousel.page";
import Hero from "./Hero.page";
import { ReadyToEatHome } from "./ReadyToEat.page";
import { ChocolatierHome } from "./Chocolatier.page";
import { ChhaganMaganHome } from "./ChhaganMagan.page";
import { AboutUsHome } from "./AboutUs.page";

const Home = () => {
  return (
    <>
      {/* <Carousel /> */}
      <Hero />
      <ReadyToEatHome />
      <ChocolatierHome />
      <ChhaganMaganHome />
      <AboutUsHome />
    </>
  );
};

export default Home;
