import styled from "@emotion/styled";
import { Box } from "@mui/system";

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 0.7rem;

  .nav-logo {
    max-width: 180px;
  }
`;

const Sidebar = styled(Box)`
  display: flex;
  height: 100vh;
  flex-direction: column;
  width: 300px;

  .sidebar-close-btn {
    display: none;
    padding: 1rem;
    justify-content: flex-start;
  }

  .sidebar-links {
    color: ${({ theme }) => theme.palette.secondary.main};
    font-size: 1.1rem;
  }
  .sidebar-flex {
    display: flex;
    justify-content: space-between;
    font-size: 1.1rem;
    padding: 1rem;
  }

  @media only screen and (max-width: 500px) {
    .sidebar-close-btn {
      display: block;
    }
  }
`;

export { Nav, Sidebar };
