import blackLogo from "../assets/logo-aspire-black.png";
import whiteLogo from "../assets/logo-aspire-white.png";
import noAddedPreservativesImg from "../assets/no-added-preservatives.png";
import noArtificialFlavoursImg from "../assets/no-artificial-flavours.png";
import noGmossImg from "../assets/no-gmoss.png";
import zeroTransFatsImg from "../assets/zero-trans-fats.png";
import whyUsImg from "../assets/why-us.png";
import guideYouImg from "../assets/guide-you.png";
import ourMissionImg from "../assets/our-mission.png";
import ourVisionImg from "../assets/our-vision.png";
import valueForMoneyImg from "../assets/value-for-money.png";
import paymentPoliciesImg from "../assets/payment-policies.png";
import quickDeliveryImg from "../assets/quick-delivery.png";
import documentationImg from "../assets/documentation.png";
import contactOrngIcon from "../assets/contact-icon.png";
import underMaintenance from "../assets/error/under_maintenance.png";
import noProductImg from "../assets/error/no_product.png";

const BlackLogo = ({ style }) => (
  <img className={style} src={blackLogo} alt="aspire logo" />
);

const WhiteLogo = ({ style }) => (
  <img className={style} src={whiteLogo} alt="aspire logo" />
);

const NoAddedPreservativesImg = ({ style }) => (
  <img
    className={style}
    src={noAddedPreservativesImg}
    alt="aspire logo"
    data-aos="flip-left"
  />
);

const NoArtificialFlavoursImg = ({ style }) => (
  <img
    className={style}
    src={noArtificialFlavoursImg}
    alt="aspire logo"
    data-aos="flip-left"
  />
);

const NoGmossImg = ({ style }) => (
  <img
    className={style}
    src={noGmossImg}
    alt="aspire logo"
    data-aos="flip-left"
  />
);

const ZeroTransFatsImg = ({ style }) => (
  <img
    className={style}
    src={zeroTransFatsImg}
    alt="aspire logo"
    data-aos="flip-left"
  />
);
const WhyUsImg = ({ style }) => (
  <img className={style} src={whyUsImg} alt="aspire logo" />
);
const GuideYouImg = ({ style }) => (
  <img className={style} src={guideYouImg} alt="aspire logo" />
);
const OurMissionImg = ({ style }) => (
  <img className={style} src={ourMissionImg} alt="aspire logo" />
);
const OurVisionImg = ({ style }) => (
  <img className={style} src={ourVisionImg} alt="aspire logo" />
);
const ValueForMoneyImg = ({ style }) => (
  <img className={style} src={valueForMoneyImg} alt="aspire logo" />
);
const PaymentPoliciesImg = ({ style }) => (
  <img className={style} src={paymentPoliciesImg} alt="aspire logo" />
);
const QuickDeliveryImg = ({ style }) => (
  <img className={style} src={quickDeliveryImg} alt="aspire logo" />
);
const DocumentationImg = ({ style }) => (
  <img className={style} src={documentationImg} alt="aspire logo" />
);
const ContactIcon = ({ style }) => (
  <img className={style} src={contactOrngIcon} alt="aspire logo" />
);

const ErrorImg = ({ style }) => (
  <img
    className={style}
    src={underMaintenance}
    alt="Under Maintenance"
    data-aos="zoom-in"
  />
);

const NoProductFound = ({ style }) => (
  <img
    className={style}
    src={noProductImg}
    alt="No Product"
    data-aos="zoom-in"
  />
);

export {
  BlackLogo,
  WhiteLogo,
  NoAddedPreservativesImg,
  NoArtificialFlavoursImg,
  NoGmossImg,
  ZeroTransFatsImg,
  WhyUsImg,
  GuideYouImg,
  OurMissionImg,
  OurVisionImg,
  ValueForMoneyImg,
  PaymentPoliciesImg,
  QuickDeliveryImg,
  DocumentationImg,
  ContactIcon,
  ErrorImg,
  NoProductFound,
};
