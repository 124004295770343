import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { Form } from "formik";

const HeroWrap = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);

  @media only screen and (max-width: 800px) {
    padding-inline: 1rem;
  }
`;

const HeroContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  & > img {
    max-width: 768px;
  }

  @media only screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`;

const HeroItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  max-width: 600px;

  & > p {
    text-align: left;
    font-size: 1.5rem;
  }
  @media only screen and (max-width: 800px) {
    & > h1 {
      font-size: 2.5rem;
    }
    & > p {
      text-align: left;
      font-size: 1.2rem;
    }
  }
`;

const HeroTitles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: left;

  .title-flex {
    display: flex;
    gap: 0.4rem;
  }

  & > h1 {
    font-size: 3rem;
    font-family: "semiBold";
    position: relative;
    text-align: left;
  }
  & > h1::after {
    content: "";
    display: block;
    position: absolute;
    width: 40%;
    height: 0.25rem;
    background-image: linear-gradient(-60deg, #ff5858 0%, #f09819 100%);
    border-radius: 50px;
    margin-top: 0.5rem;
    transform: skew(45deg);
  }

  & > span > h5 {
    font-family: "SemiBold";
  }

  @media only screen and (max-width: 800px) {
    & > h1 {
      font-size: 2.5rem;
    }
    & > spna h5 {
      font-size: 1.5rem;
    }
  }
`;

const Section = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  gap: 2.5rem;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ pt }) => pt && `${pt}rem`};

  & > h2 {
    font-family: "semiBold";
    position: relative;
  }
  & > h2::after {
    content: "";
    display: block;
    position: absolute;
    width: 40%;
    height: 0.21rem;
    background-image: linear-gradient(-60deg, #ff5858 0%, #f09819 100%);
    border-radius: 50px;
    margin-top: 0.2rem;
    transform: skew(45deg);
  }

  & > h6 {
    text-align: center;
  }

  @media only screen and (max-width: 500px) {
    & > h6 {
      text-align: justify;
    }
  }
`;

const SectionContainer = styled.div`
  padding-block: 2rem;
`;

const SectionButton = styled(Button)`
  background: linear-gradient(to bottom, #323232 0%, #3f3f3f 40%, #1c1c1c 150%),
    linear-gradient(
      to top,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(0, 0, 0, 0.25) 200%
    );
  background-blend-mode: multiply;
  padding-inline: 1.5rem;
`;

const ContactUsWrap = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-content: center;

  @media only screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
  }
`;

const ContactItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;

  & > h3 {
    position: relative;
  }
  & > h3::after {
    content: "";
    display: block;
    position: absolute;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    width: 30%;
    height: 0.2rem;
    border-radius: 50px;
  }

  @media only screen and (max-width: 500px) {
    gap: 2rem;
    & > h3 {
      font-size: 2rem;
    }
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media only screen and (max-width: 500px) {
    gap: 1rem;
  }
`;

const ContactForm = styled(Form)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  width: 100%;
  font-size: 1rem;

  .col-span {
    grid-column: 1 / span 2;
  }

  @media only screen and (max-width: 500px) {
    gap: 1rem;
  }
`;

const ProductViewWrap = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-content: center;
  padding: 5rem 1rem;

  @media only screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }

  .grid-two {
    @media only screen and (max-width: 500px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      justify-items: center;

      & > img {
        max-width: 160px;
      }
    }
  }
`;

const ProductItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  & > img {
    max-width: 100%;
  }
  & > h3 {
    position: relative;
  }
  & > h3::after {
    content: "";
    display: block;
    position: absolute;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    width: 30%;
    height: 0.2rem;
    border-radius: 50px;
  }
`;

const ProductTitles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & > h3 {
    position: relative;
  }
  & > h3::after {
    content: "";
    display: block;
    position: absolute;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    width: 30%;
    height: 0.2rem;
    border-radius: 50px;
  }
`;

const ProductDetailsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & > p {
    text-align: justify;
  }
`;

const ProductFLexChip = styled.span`
  display: flex;
  gap: 1rem;
`;

const AboutUsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 4rem 0;

  @media only screen and (max-width: 500px) {
    padding: 0 1rem;
  }
`;

const AboutItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  gap: 2rem;
  padding: 2rem;

  & > img {
    width: 100%;
  }

  & > h2 {
    position: relative;
  }
  & > h2::after {
    content: "";
    display: block;
    position: absolute;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    width: 30%;
    height: 0.2rem;
    border-radius: 50px;
  }

  @media only screen and (max-width: 500px) {
    width: 100%;
    padding: 2rem 0;
  }
`;

const WhyUsContent = styled.div`
  display: flex;
  gap: 2rem;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

const AboutUsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;

  & > h3 {
    position: relative;
  }
  & > h3::after {
    content: "";
    display: block;
    position: absolute;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    width: 30%;
    height: 0.2rem;
    border-radius: 50px;
  }

  .txt-center {
    text-align: center;
  }
`;

const AboutUsCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  background-image: linear-gradient(
    -225deg,
    #f3e7e9 0%,
    #e3eeff 50%,
    #e3eeff 100%
  );

  border-radius: 20px;
  padding: 2rem;

  & > img {
    max-width: 4rem;
  }
`;

const ServicesBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
  padding: 2rem;
  & > img {
    max-width: 6rem;
  }
  @media only screen and (max-width: 500px) {
    padding: 2rem 0;
  }
`;

const ErrorWrap = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;

  & > img {
    max-width: 768px;
  }

  @media only screen and (max-width: 800px) {
    & > img {
      max-width: 500px;
    }
  }

  @media only screen and (max-width: 500px) {
    padding-inline: 1rem;
    & > img {
      max-width: 400px;
    }
  }
`;

const ErrorWrapTwo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;

  & > img {
    max-width: 400px;
  }

  @media only screen and (max-width: 500px) {
    padding-inline: 1rem;
    & > img {
      max-width: 400px;
    }
  }
`;

const ErrorMessageWrap = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > h3 {
    font-size: 3rem;
    font-family: "semiBold";
    position: relative;
    text-align: left;
  }
`;

export {
  HeroWrap,
  HeroContent,
  HeroTitles,
  HeroItem,
  Section,
  SectionContainer,
  SectionButton,
  ContactUsWrap,
  ContactItem,
  ContactInfo,
  ContactForm,
  ProductViewWrap,
  ProductFLexChip,
  ProductItem,
  ProductTitles,
  ProductDetailsWrap,
  AboutUsWrap,
  AboutItem,
  WhyUsContent,
  AboutUsContent,
  AboutUsCard,
  ServicesBox,
  ErrorWrap,
  ErrorWrapTwo,
  ErrorMessageWrap,
};
