import { useNavigate } from "react-router-dom";
import { Chip, Divider, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { MyContext } from "../context/context";
import {
  Grid,
  PageNavbar,
  ProductCard,
  ProductCardContent,
  ProductCardDescription,
  ProductCardMedia,
  ProductCardSubTitle,
  ProductCardTitle,
  SearchBar,
  SearchInput,
  SeeAllProducts,
} from "../style/global.style";
import {
  ErrorMessageWrap,
  ErrorWrapTwo,
  ProductDetailsWrap,
  ProductFLexChip,
  ProductItem,
  ProductTitles,
  ProductViewWrap,
  Section,
} from "../style/page.style";

import { useSearchParams } from "react-router-dom";
import {
  NoAddedPreservativesImg,
  NoArtificialFlavoursImg,
  NoGmossImg,
  NoProductFound,
  ZeroTransFatsImg,
} from "../components/Image";
import { isEmpty, lowerCase } from "lodash";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

const ReadyToEatHome = () => {
  const navigate = useNavigate();
  const { frixtyProductList } = useContext(MyContext);

  const readyToEatHomeProduct = frixtyProductList.slice(0, 3);

  return (
    <Section className="container">
      <Typography variant="h2" data-aos="zoom-in">
        Ready to Eat
      </Typography>
      <Grid item="3" gap="1">
        {readyToEatHomeProduct.map((item, index) => {
          const { productId, productName, category, description, image } = item;
          return (
            <Link
              key={productId}
              data-aos="flip-left"
              to={`/product/ready-to-eat/view?productId=${productId}`}
            >
              <ProductCard variant="outlined">
                <ProductCardMedia
                  component="img"
                  image={image}
                  sx={{
                    backgroundColor: "white",
                  }}
                />
                <ProductCardContent>
                  <ProductCardTitle
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "1",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {productName}
                  </ProductCardTitle>
                  <ProductCardSubTitle>{category}</ProductCardSubTitle>
                  <ProductCardDescription
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {description}
                  </ProductCardDescription>
                </ProductCardContent>
              </ProductCard>
            </Link>
          );
        })}
      </Grid>
      <SeeAllProducts
        variant="contained"
        disableElevation
        onClick={() => navigate("/product/ready-to-eat")}
      >
        See All
      </SeeAllProducts>
    </Section>
  );
};

const ReadyToEatProductsLists = () => {
  const { frixtyProductList } = useContext(MyContext);
  const [searchInitVal, setSearchInitVal] = useState("");
  const searchValLen = searchInitVal.length;
  const finalProductList = frixtyProductList.filter((i) => {
    return (
      lowerCase(i.productName).slice(0, searchValLen) ===
      lowerCase(searchInitVal)
    );
  });

  return (
    <Section className="container" pt="5">
      <PageNavbar elevation={0}>
        <SearchBar>
          <SearchInput
            placeholder="Search"
            inputProps={{ "aria-label": "search google maps" }}
            value={searchInitVal}
            onChange={(e) => setSearchInitVal(e.target.value)}
          />
          <IconButton type="button" aria-label="search">
            <SearchIcon />
          </IconButton>
          {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
        </SearchBar>
      </PageNavbar>
      {!isEmpty(finalProductList) ? (
        <>
          <Typography variant="h2" data-aos="zoom-in">
            Ready to Eat
          </Typography>
          <Grid item="3" gap="1">
            {finalProductList.map((item, index) => {
              const { productId, productName, category, description, image } =
                item;
              return (
                <Link
                  key={productId}
                  data-aos="flip-left"
                  to={`view?productId=${productId}`}
                >
                  <ProductCard variant="outlined">
                    <ProductCardMedia
                      component="img"
                      image={image}
                      sx={{
                        backgroundColor: "white",
                      }}
                    />
                    <ProductCardContent>
                      <ProductCardTitle
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: "1",
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {productName}
                      </ProductCardTitle>
                      <ProductCardSubTitle>{category}</ProductCardSubTitle>
                      <ProductCardDescription
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: "2",
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {description}
                      </ProductCardDescription>
                    </ProductCardContent>
                  </ProductCard>
                </Link>
              );
            })}
          </Grid>
        </>
      ) : (
        <ErrorWrapTwo>
          <NoProductFound />
          <ErrorMessageWrap>
            <Typography variant="h3" data-aos="zoom-in">
              Oops !!
            </Typography>
            <Typography variant="h6" data-aos="zoom-in">
              Your search result was not found.
            </Typography>
          </ErrorMessageWrap>
        </ErrorWrapTwo>
      )}
    </Section>
  );
};

const ReadyToEatProductView = () => {
  const { frixtyProductList } = useContext(MyContext);
  const [param] = useSearchParams();
  const productId = param.get("productId") - 1;

  const product = frixtyProductList[productId];

  return (
    <ProductViewWrap className="container">
      <ProductItem>
        <img src={product.image} alt="Hero" data-aos="zoom-in" />
      </ProductItem>
      <ProductItem>
        <ProductTitles>
          <Typography variant="h3" data-aos="zoom-in">
            {product.productName}
          </Typography>
          <Typography variant="h6" data-aos="zoom-in">
            {product.category}
          </Typography>
        </ProductTitles>
        <Divider data-aos="zoom-in" />
        <Grid item="4" gap="1" className="grid-two">
          <NoAddedPreservativesImg style={`img-max-wd-8`} />
          <NoArtificialFlavoursImg style={`img-max-wd-8`} />
          <NoGmossImg style={`img-max-wd-8`} />
          <ZeroTransFatsImg style={`img-max-wd-8`} />
        </Grid>
        <ProductDetailsWrap>
          <Typography variant="h5" data-aos="zoom-in">
            Description
          </Typography>
          <Typography variant="body1" data-aos="zoom-in">
            {product.description}
          </Typography>
        </ProductDetailsWrap>
        <Grid item="2" gap="1">
          <ProductDetailsWrap>
            <Typography variant="h5" data-aos="zoom-in">
              Net Weight
            </Typography>
            <ProductFLexChip>
              <Chip
                label={product.netWeight}
                color="secondary"
                variant="outlined"
                data-aos="zoom-in"
              />
            </ProductFLexChip>
          </ProductDetailsWrap>
          <ProductDetailsWrap>
            <Typography variant="h5" data-aos="zoom-in">
              Rehydrated Weight
            </Typography>
            <ProductFLexChip>
              <Chip
                label={product.rehydrateWeight}
                color="secondary"
                variant="outlined"
                data-aos="zoom-in"
              />
            </ProductFLexChip>
          </ProductDetailsWrap>
        </Grid>
      </ProductItem>
    </ProductViewWrap>
  );
};

export { ReadyToEatHome, ReadyToEatProductsLists, ReadyToEatProductView };
