import { Typography } from "@mui/material";
import { ErrorImg } from "../components/Image";
import { ErrorMessageWrap, ErrorWrap } from "../style/page.style";

const Error = () => {
  return (
    <ErrorWrap>
      <ErrorImg />
      <ErrorMessageWrap>
        <Typography variant="h3" data-aos="zoom-in">
          Under Mintenance
        </Typography>
        <Typography variant="h6" data-aos="zoom-in">
          Please wait while we prepare something special for you.
        </Typography>
      </ErrorMessageWrap>
    </ErrorWrap>
  );
};

export default Error;
