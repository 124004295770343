import { createContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import "yup-phone";
import emailjs from "@emailjs/browser";

/**
 * @ carousel Images
 */

import carouselOneGujaratiDal from "../assets/carousel/carousel-1-gujarati_dal.png";
import carouselTwoPoha from "../assets/carousel/carousel-2-poha.png";
import carouselThreeCsSilkyFamilyPack from "../assets/carousel/carousel-3-cs-silky-family-pack.png";
import carouselFourCbChocolateBouquet from "../assets/carousel/carousel-4-cb-chocolate-bouquet-1.png";
import carouselFiveNamkeenTikhaMithaChevda from "../assets/carousel/carousel-5-namkeen-tikha-mitha-chevda.png";
import carouselSixSoyaStick from "../assets/carousel/carousel-6-soya-stick.png";

/**
 * @ Frixty Images
 */
import choliSabjiImg from "../assets/frixty/choli_sabji.png";
import dalBhatImg from "../assets/frixty/dal-bhat.png";
import dalDhokaliImg from "../assets/frixty/dal-dhokali.png";
import gujaratiDalImg from "../assets/frixty/gujarati_dal.png";
import gujarDalwaImg from "../assets/frixty/gujar-halwa.png";
import kadhiBhatImg from "../assets/frixty/kadhi_bhat.png";
import kajuCurryImg from "../assets/frixty/kaju_curry.png";
import masalaKadhiKhichadiImg from "../assets/frixty/masala-kadhi-khichadi.png";
import mohanThadImg from "../assets/frixty/mohan-thad.png";
import moongDalShiraImg from "../assets/frixty/moong-dal-shira.png";
import moongSabjiImg from "../assets/frixty/moong-sabji.png";
import palakPaneerImg from "../assets/frixty/palak-paneer.png";
import paneerBhurjiImg from "../assets/frixty/paneer-bhurji.png";
import pavBhajiImg from "../assets/frixty/pav-bhaji.png";
import pohaImg from "../assets/frixty/poha.png";
import pulaoImg from "../assets/frixty/pulao.png";
import ravaShiroImg from "../assets/frixty/rava-shiro.png";
import schezwanRiceImg from "../assets/frixty/schezwan-rice.png";
import suratiKadhiImg from "../assets/frixty/surati-kadhi.png";
import tawaPulaoImg from "../assets/frixty/tawa-pulao.png";
import udadDalImg from "../assets/frixty/udad-dal.png";
import upmaImg from "../assets/frixty/upma.png";
import VadiSabjiImg from "../assets/frixty/Vadi-sabji.png";
import valSabjiImg from "../assets/frixty/val-sabji.png";
import vegBiryaniImg from "../assets/frixty/veg-biryani.png";
import dalMakhaniImg from "../assets/frixty/dal-makhani.png";
import paneerButterMasalaImg from "../assets/frixty/paneer-butter-masala.png";

/** */

/**
 * @ Chocolatier Images
 */
/** 1) Bars */
import barAlmodCranberryOne from "../assets/chocolatier/bar-almod-cranberry-1.jpg";
import barAlmodCranberryTwo from "../assets/chocolatier/bar-almod-cranberry-2.jpg";

import barBlendChocolateHezalnutsOne from "../assets/chocolatier/bar-blend-chocolate-hezalnuts-1.jpg";
import barBlendChocolateHezalnutsTwo from "../assets/chocolatier/bar-blend-chocolate-hezalnuts-2.jpg";

import barBlendedChocolateOne from "../assets/chocolatier/bar-blended-chocolate-1.jpg";
import barBlendedChocolateTwo from "../assets/chocolatier/bar-blended-chocolate-2.jpg";
import barBlendedChocolateThree from "../assets/chocolatier/bar-blended-chocolate-3.jpg";

import barCranberryOne from "../assets/chocolatier/bar-cranberry-1.jpg";
import barCranberryTwo from "../assets/chocolatier/bar-cranberry-2.jpg";
import barCranberryThree from "../assets/chocolatier/bar-cranberry-3.jpg";
import barCranberryFour from "../assets/chocolatier/bar-cranberry-4.jpg";

import barFruitsNutsOne from "../assets/chocolatier/bar-fruits-nuts-1.jpg";
import barFruitsNutsTwo from "../assets/chocolatier/bar-fruits-nuts-2.jpg";

import barMilkChocolateOne from "../assets/chocolatier/bar-milk-chocolate-1.jpg";
import barMilkChocolateTwo from "../assets/chocolatier/bar-milk-chocolate-2.jpg";
import barMilkChocolateThree from "../assets/chocolatier/bar-milk-chocolate-3.jpg";

import barMilkChocolateRostedAlomodOne from "../assets/chocolatier/bar-milk-chocolate-rosted-alomod-1.jpg";
import barMilkChocolateRostedAlomodTwo from "../assets/chocolatier/bar-milk-chocolate-rosted-alomod-2.jpg";
import barMilkChocolateRostedAlomodThree from "../assets/chocolatier/bar-milk-chocolate-rosted-alomod-3.jpg";

import barRoastedAlmondOne from "../assets/chocolatier/bar-roasted-almond-1.jpg";
import barRoastedAlmondTwo from "../assets/chocolatier/bar-roasted-almond-2.jpg";
import barRoastedAlmondThree from "../assets/chocolatier/bar-roasted-almond-3.jpg";

import barWholeAlmond from "../assets/chocolatier/bar-whole-almond.jpg";

/** 2) Almond Hearts   */
import ahAlmondHeart from "../assets/chocolatier/ah-almond-heart.jpg";
import ahAlmondHeartLava from "../assets/chocolatier/ah-almond-heart-lava.jpg";

import ahAlmondHeartThreePiecesBoxOne from "../assets/chocolatier/ah-almond-heart-3-pieces-box-1.jpg";
import ahAlmondHeartThreePiecesBoxTwo from "../assets/chocolatier/ah-almond-heart-3-pieces-box-2.jpg";

/** 3) Rich Assorted Collections */
import racHeartOne from "../assets/chocolatier/rac-heart-1.jpg";
import racHeartTwo from "../assets/chocolatier/rac-heart-2.jpg";
import racHeartThree from "../assets/chocolatier/rac-heart-3.jpg";

import racWhiteGoldenBoxOne from "../assets/chocolatier/rac-white-golden-box-1.jpg";
import racWhiteGoldenBoxTwo from "../assets/chocolatier/rac-white-golden-box-2.jpg";

/** 4) Rich Day Fruit Collections */
import rdfcRedHeartPurseOne from "../assets/chocolatier/rdfc-red-heart-purse-1.jpg";
import rdfcRedHeartPurseTwo from "../assets/chocolatier/rdfc-red-heart-purse-2.jpg";

/** 5) Classic Silky */
import csSilkyFamilyPackOne from "../assets/chocolatier/cs-silky-family-pack-1.jpg";
import csSilkyFamilyPackTwo from "../assets/chocolatier/cs-silky-family-pack-2.jpg";
import csSilkyFamilyPackThree from "../assets/chocolatier/cs-silky-family-pack-3.jpg";

//** 6) Customized Gifts Box */
import cgbCarOne from "../assets/chocolatier/cgb-car-1.jpg";
import cgbCarTwo from "../assets/chocolatier/cgb-car-2.jpg";

import cgbHouseOne from "../assets/chocolatier/cgb-house-1.jpg";
import cgbHouseTwo from "../assets/chocolatier/cgb-house-2.jpg";

import cgbPiperBox from "../assets/chocolatier/cgb-piper-box.jpg";

//** 7) Truffles */
import trufflesPouch from "../assets/chocolatier/truffles-pouch.jpg";

import trufflesRectangleBoxOne from "../assets/chocolatier/truffles-rectangle-box-1.jpg";
import trufflesRectangleBoxTwo from "../assets/chocolatier/truffles-rectangle-box-2.jpg";

import trufflesRoundBoxOne from "../assets/chocolatier/truffles-round-box-1.jpg";
import trufflesRoundBoxTwo from "../assets/chocolatier/truffles-round-box-2.jpg";

import trufflesSquareBoxOne from "../assets/chocolatier/truffles-square-box-1.jpg";
import trufflesSquareBoxTwo from "../assets/chocolatier/truffles-square-box-2.jpg";

//** 8) Chocolate Bouquet */
import cbBlackGoldenbBouquetOne from "../assets/chocolatier/cb-black-goldenb-bouquet-1.jpg";
import cbBlackGoldenbBouquetTwo from "../assets/chocolatier/cb-black-goldenb-bouquet-2.jpg";

import cbChocolateBouquetOne from "../assets/chocolatier/cb-chocolate-bouquet-1.jpg";
import cbChocolateBouquetTwo from "../assets/chocolatier/cb-chocolate-bouquet-2.jpg";

//** 9) Celebration Box */
import celebrationEmeraldBox from "../assets/chocolatier/celebration-emerald-box.jpg";

import celebrationRoyalWhiteBoxOne from "../assets/chocolatier/celebration-royal-white-box-1.jpg";
import celebrationRoyalWhiteBoxTwo from "../assets/chocolatier/celebration-royal-white-box-2.jpg";

import celebrationThreeJarHamper from "../assets/chocolatier/celebration-three-jar-hamper.jpg";

import celebrationTwoJarHamper from "../assets/chocolatier/celebration-two-jar-hamper.jpg";

import celebrationYellowJeliBox from "../assets/chocolatier/celebration-yellow-jeli-box.jpg";
/** */

/**
 * @ Chhagan Magan
 */
import chipsSaltedPatatoImg from "../assets/chhagan-magan/chips-salted-patato.png";
import chipsMasalaPotatoImg from "../assets/chhagan-magan/chips-masala-potato.png";
import bhakhriImg from "../assets/chhagan-magan/bhakhri.png";
import frenchFriesImg from "../assets/chhagan-magan/french -fries.png";
import namkeenKhataMithaChevdaImg from "../assets/chhagan-magan/namkeen-khata-mitha-chevda.png";
import namkeenTikhaMithaChevdaImg from "../assets/chhagan-magan/namkeen-tikha-mitha-chevda.png";
import soyaStickImg from "../assets/chhagan-magan/soya-stick.png";
import masalaKhakharaImg from "../assets/chhagan-magan/Khakhara.png";

const MyContext = createContext();

const MyProvider = ({ children }) => {
  const navigate = useNavigate();
  const [render, setRender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabledFormField, setDisabledFormField] = useState(false);
  const navigateToError = () => {
    console.log("clickeddd");
    navigate("error");
  };
  /** Contact us  */

  const contactDetails = {
    email: "business@aspireimpex.com",
    contactNo: "+91 9408541111 - Kishan Ravaliya (Proprietor)",
    address: "306, The Planet Complex, Nr. Vadla Fatak, Junagadh.",
  };

  const contactUsFormInitialValues = {
    firstname: "",
    lastname: "",
    email: "",
    phoneNo: "",
    message: "",
  };

  const contactUsFormVlidation = Yup.object({
    firstname: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please provide a valid Firstname.")
      .max(10, "The firstname cannot be longer than 10 characters.")
      .required("You must provide your Firstname."),

    lastname: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please provide a valid Firstname.")
      .max(10, "The lasname cannot be longer than 10 characters.")
      .required("You must provide your Lastname."),

    email: Yup.string()
      .email("Please provide an email address that is genuine.")
      .required("You must provide your email address."),

    phoneNo: Yup.string()
      .min(10, "The phone number must be at least 10 characters long.")
      .max(
        10,
        "There are a maximum of 10 characters allowed for the phone number."
      )
      .phone("IN", true, "Phone No. must be a valid phone number for region IN")
      .required("You must provide your Phone No."),

    message: Yup.string()
      .min(16, "The message is too brief.")
      .max(160, "The message is too lengthy.")
      .required("Please write your message."),
  });

  const mailingCredentials = {
    serviceId: "service_lr8siwr",
    templateId: "template_bctbu54",
    publicKey: "RUobp2CdoqK4OAy8-",
  };

  const [snackbarState, setSnackbarState] = useState(false);

  const handleSnakebarClose = () => {
    setSnackbarState(!snackbarState);
    setRender(!render);
  };

  const handleContactUsForm = ({ values, resetForm }) => {
    setSnackbarState(true);
    setDisabledFormField(true);
    setLoading(true);
    setRender(true);

    const { serviceId, templateId, publicKey } = mailingCredentials;

    emailjs.send(serviceId, templateId, values, publicKey).then(
      (result) => {
        resetForm();
        setLoading(false);
        setDisabledFormField(false);
      },
      (error) => {
        navigate("/");
      }
    );
  };

  const [carouselProductsState] = useState([
    {
      productId: "1",
      productName: "Gujarati Dal",
      category: "Ready To Eat",
      subCategory: "Sun Dried",
      description:
        "Toor dal is an incredible source of proteins, carbs and dietary fibre needed for growth and development. Adding toor dal in your diet helps to meet your demands of iron and calcium while being a good source of folic acids promotes foetal growth and averts congenital birth defects of the foetus.",
      image: carouselOneGujaratiDal,
    },
    {
      productId: "2",
      productName: "Poha",
      category: "Ready To Eat",
      subCategory: "Frreze Dried",
      description:
        "Controls Blood Sugar Levels. Poha is considered a good meal for diabetics. A Good Probiotic. This may come as a surprise to many, but poha is a good probiotic food too. A Good Source of Healthy Carbohydrates. Easily Digestible. It has Rich In Iron. It has Low In Calories.",
      image: carouselTwoPoha,
    },
    {
      productId: "3",
      productName: "Silky Family Pack",
      category: "Chocolatier",
      subCategory: "Classic Silky",
      description:
        "Silky smooth handcradted pure milk chocolate with 31% choco made with finest ghana choco is simply lrresistible.",
      image: carouselThreeCsSilkyFamilyPack,
    },
    {
      productId: "4",
      productName: "Chocolate Bouquet",
      category: "Chocolatier",
      subCategory: "Chocolate Bouquet",
      description:
        "Belgiano mini bar, Belgiano bar, Classic silky, loose & Roasted Almond bar.",
      image: carouselFourCbChocolateBouquet,
    },
    {
      productId: "5",
      productName: "Tikha Mitha Chevda",
      category: "Snacks",
      subCategory: "Namkeen",
      description:
        "Try out our Namkeen products; they are always fresh and restful. Open our chhagan magan Tikha Mitha Chevda and eat it.",
      image: carouselFiveNamkeenTikhaMithaChevda,
    },
    {
      productId: "6",
      productName: "Soya",
      category: "Snacks",
      subCategory: "Soya Stick",
      description:
        "With this variation of Chhagan Magan's original recipe, you will discover the deliciousness of chatpata, crispy sticks of protein-rich soya. These crisps are healthy and substantial.",
      image: carouselSixSoyaStick,
    },
  ]);

  const [frixtyProductListState] = useState([
    {
      productId: "1",
      productName: "Rava Shira",
      category: "Sun Dried",
      netWeight: "200 gm",
      rehydrateWeight: "400 gm",
      description:
        "Soji is high in protein and fiber — both of which slow digestion and increase feelings of fullness between meals. It’s also high in B vitamins like thiamine and folate, which have many important roles in your body, including helping convert food into energy.",
      ingredient:
        "Soji(36%), Sugar(28%), Ghee(28%) and dry fruits (cashew nuts, almonds and raisins)(8%).",
      image: ravaShiroImg,
    },
    {
      productId: "2",
      productName: "Mohan Thal",
      category: "Sun Dried",
      netWeight: "200 gm",
      rehydrateWeight: "400 gm",
      description:
        "Raw material of Mohanthal BESAN have Lowers the risk of cardiovascular diseases. Contains 101% of the recommended daily folate intake. Helps in maintaining muscle mass and strength. Higher in protein than other flours. Ideal for weight loss. Fewer calories than whole wheat flour, helps you feel full. Regulates blood sugar levels.",
      ingredient:
        "Gram dal flour(36%), Ghee (32%), Sugar (25%), Nuts (6.9% ), cardamom (0.1%)",
      image: mohanThadImg,
    },
    {
      productId: "3",
      productName: "Kaju Curry",
      category: "Sun Dried",
      netWeight: "120 gm",
      rehydrateWeight: "420 gm",
      description:
        "Keeps Your Heart Healthy. Unlike popular belief, cashew nuts or kaju’s are good for heart health. It’s Good for Bone Health. Lowers The Risk of Diabetes. Boosts Brain Function and Lowers the Risk of Gallstones.",
      ingredient:
        "cashew nuts(51%),Deide Vegetables(Kasuri Methi,ginger,Chilies,Oninon, Garlic & Tomato)(20%), Powdered Spices & Condiments(7.5%), Watermelon Seeds(6%),Ghee(6%),Refined Sunflower Oil(6%), Compounded Asafoetida(2%), Iodized Salt(1.5%),Whole Cumin(0.5%).",
      image: kajuCurryImg,
    },
    {
      productId: "4",
      productName: "Choli (Cowpea) Sabji",
      category: "Sun Dried",
      netWeight: "150 gm",
      rehydrateWeight: "850 gm",
      description:
        "Cowpea beans are excellent sources of dietary fiber, protein, B vitamins and many other important vitamins and minerals. There is good evidence that they can help reduce blood sugar, improve cholesterol levels and help maintain a healthy gut.",
      ingredient:
        "Cowpea (60%), Refined Sunflower oil (12%), Powdered Spices (coriander,cumin,chillies and turmeric)(8%), (Dried vegetables (tomato, ginger, garlic, green chillies) (6%) Sugar (6%), Iodised Salt(3.5%), whole spicies(cumin, mustard) (3 %) , Compounded Asafoetida (2%)",
      image: choliSabjiImg,
    },
    {
      productId: "5",
      productName: "Moong Sabji",
      category: "Sun Dried",
      netWeight: "150 gm",
      rehydrateWeight: "850 gm",
      description:
        "Moong Helps in healthy weight management – pulses like yellow dal, green dal is extremely rich in fiber and protein. This works for weight loss and healthy weight management. Incorporating moong dal into your daily diet improves your immune system and reduces the risk of developing any diseases.",
      ingredient:
        "Moong (60%), Refined Sunflower oil (11%), Sugar(8%),Powdered Spices (coriander cumin, chillies and turmeric)(6%), Iodised Salt(3%), gram flour (3%), rice flour ( 3%), Whole Spices( Cumin, mustard ) (2.7%) (Dried vegetables (tomato, ginger, garlic, green chillies and lemon powder) (2%), Compounded Asafoetida (1%) and Curry Leaves (0.3%).",
      image: moongSabjiImg,
    },
    {
      productId: "6",
      productName: "Val Sabji",
      category: "Sun Dried",
      netWeight: "150 gm",
      rehydrateWeight: "850 gm",
      description:
        "The val beans are rich in protein, calcium and folic acid, and can be eaten cooked, semi-cooked and fried too. The beans can be fried, causing the skin to split open, and then salted and/or spiced to produce a savoury crunchy snack that is loved by all.",
      ingredient:
        "Indian bean (60%), Refined Sunflower oil (12%), dried vegetables (Tomato-,ginger- lemon powder and garlic-green chillies) ( 7 % ), Powdered Spices (coriander-cumin , chillies and turmeric) (6.5%), Sugar (6%), Iodised Salt (4%), whole spices (cumin, mustard) (2.5%) and Compounded Asafoetida (2%)",
      image: valSabjiImg,
    },
    {
      productId: "7",
      productName: "Udad Dal",
      category: "Sun Dried",
      netWeight: "150 gm",
      rehydrateWeight: "850 gm",
      description:
        "Udad dal has a good amount of potassium that is meant to boost blood circulation, thereby eliminating chances of damage on the arterial walls. Enriched with the maximum amount of magnesium, fibre, udad dal can keep the cholesterol levels under check. Atherosclerosis is averted with the proper intake of udad dal.",
      ingredient:
        "Udad Dal (72%) ,Refined Sunflower oil( 7%),Dried vegetables (ginger,garlic, green chillies and tomato and lemon powder)(6.5%), Powdered Spices (coriander-cumin,chillies and turmeric) (5.5%) ,Iodised Salt(4%),, whole spicies(cumin, mustard) (3.5 %) Compounded Asafoetida (1%), and Curry Leaves (0.5%).",
      image: udadDalImg,
    },
    {
      productId: "8",
      productName: "Vadi Sabji",
      category: "Sun Dried",
      netWeight: "150 gm",
      rehydrateWeight: "850 gm",
      description:
        "Cowpea beans are excellent sources of dietary fiber, protein, B vitamins and many other important vitamins and minerals. There is good evidence that they can help reduce blood sugar, improve cholesterol levels and help maintain a healthy gut.",
      ingredient:
        "Gram dal flour(39%), Rice flour(12%), Refined Sunflower oil(11%), Sugar(11%), Iodised Salt(9%), Powdered Spices (coriander-cumin, chillies and turmeric)(6%), Dried Vegetables (Tomato ginger powder and garlic – greenchillies ) (6%) , whole spices (cumin, mustard) (3%) Compounded Asafoetida (2%) and Curry leaves (1%).",
      image: VadiSabjiImg,
    },
    {
      productId: "9",
      productName: "Gujarati Dal",
      category: "Sun Dried",
      netWeight: "150 gm",
      rehydrateWeight: "1150 gm",
      description:
        "Toor dal is an incredible source of proteins, carbs and dietary fibre needed for growth and development. Adding toor dal in your diet helps to meet your demands of iron and calcium while being a good source of folic acids promotes foetal growth and averts congenital birth defects of the foetus.",
      ingredient:
        "Arhar dal (27%), Jaggery (27%), Refined Sun flower oil (11%), Iodised salt (10%), sugar (8%), Powdered spices (coriander, cumin, chillies, turmeric) (7%), dried vegetables (tomato ginger powder and green chillies)(5%), Whole spices (mustard, cumin, fenugreek) (2%), tamarind (1%), compounded Asafoetida (0.5%) and curry leaves (0.5%).",
      image: gujaratiDalImg,
    },
    {
      productId: "10",
      productName: "Surati Kadhi",
      category: "Sun Dried",
      netWeight: "150 gm",
      rehydrateWeight: "1150 gm",
      description:
        "You may never know but kadhi, a traditional dish made with curd and besan (gram flour) can help prevent dehydration, loss of appetite and several other conditions that are common in summer.",
      ingredient:
        "Gram dal flour (29%), Jaggery (29% ), Sugar (8%), Iodised salt (10%), Refined Sun flower oil (8%), whole spices (mustard, cummin, fenugreek, cardamom, cassia and cloves) (6%), dried vegetable power (ginger and green chillies) (4.5%)ghee (3%), compounded Asafoetida (1%), curry leaves(1%), bay leaf (0.5%)",
      image: suratiKadhiImg,
    },
    {
      productId: "11",
      productName: "Dal Bhat",
      category: "Sun Dried",
      netWeight: "150 gm",
      rehydrateWeight: "850 gm",
      description:
        "Dal rice is high in fiber and antioxidants. You are likely to get Vitamin A, D, E and K all at once by eating this very easy-to-prepare staple Indian dish. It is one dish which can aid digestion, improve your metabolism, reduce inflammation in the body, promote weight loss and build immunity.",
      ingredient:
        "Rice (60%), Arhar dal (11%), Jaggery (11%), Refined Sun flower oil (4.5%), Iodised salt (4%), sugar (4%), Powdered spices (coriander, cumin,chillies ,turmeric) (3%), dried vegetables (tomato powder, ginger and green chillies) ( 2%), kokum(0.2%), Whole spices (cumin, mustard and fenugreek) (0.1% ), compounded Asafoetida(0.1%) and curry leaves (0.1%).",
      image: dalBhatImg,
    },
    {
      productId: "12",
      productName: "Kadhi Bhat",
      category: "Sun Dried",
      netWeight: "150 gm",
      rehydrateWeight: "850 gm",
      description:
        "You may never know but kadhi, a traditional dish made with curd and besan (gram flour) can help prevent dehydration, loss of appetite and several other conditions that are common in summer.",
      ingredient:
        "Rice (60%), Gram dal flour (11.5%), Jaggery (11% ), Iodised salt (4%), Sugar (3%), Refined Sun flower oil (3%), whole spices (mustard, cummin, fenugreek, cardamom, cassia and cloves) (3%), dried vegetable powder (ginger and green chillies) (2%)ghee (1.5%), compounded Asafoetida (0.5%), curry leaves(0.4%), bay leaf (0.1%)",
      image: kadhiBhatImg,
    },
    {
      productId: "13",
      productName: "Pulav",
      category: "Sun Dried",
      netWeight: "150 gm",
      rehydrateWeight: "850 gm",
      description:
        "Good source of fiber. Vegetable pulao is a rich source of fiber and can keep you full for longer hours. Along with fiber, this dish can provide many other essential nutrients as well.",
      ingredient:
        "Rice (72 % ) , Dried vegetables ( carrot , french bean and green peas ) (8 %) , Dry fruits ( cashew nuts and raisins )(6 %), ghee (4%) ,Iodised salt ( 3%) , Refined Sun flower oil (3%)l, Whole spices and condiments (cinnamom, cloves, cardamom and cumin ( 2.5 % ), compounded Asafoetida (1%), bay leaves(0.5%)",
      image: pulaoImg,
    },
    {
      productId: "14",
      productName: "Dal Dhokali",
      category: "Sun Dried",
      netWeight: "200 gm",
      rehydrateWeight: "1400 gm",
      description:
        "Besan roti helps in Fights allergies, helps in weight loss,Helps in skin tightening. It’s high in fiber and rich in protein which works well for diabetics. Toor dal is an incredible source of proteins, carbs and dietary fibre needed for growth and development.",
      ingredient:
        "wheat Flour (23%), Arhar dal (12%), Jaggery (12%), Gram Flour (8%), rice Flour (8 %), Sugar (8%), Peanut (6.5%), Iodised Salt (5%), Powdered Spices ( Coriander, Cumin, Chillies, Turmeric)(5%),Dried vegetables (tomato ,Ginger powder and Green Chillies)(2.5%), Dry Dates (2%), Cashew nuts (2%), whole spices (Mustard, Cumin, Fenugreek) (0.5%), Kokum (0.2%), Compounded Asafoetida (0.1%), Tamarind powder (0.1%) and Curry Leaves (0.1%).",
      image: dalDhokaliImg,
    },
    {
      productId: "15",
      productName: "Poha",
      category: "Frreze Dried",
      netWeight: "70 gm",
      rehydrateWeight: "170 gm",
      description:
        "Controls Blood Sugar Levels. Poha is considered a good meal for diabetics. A Good Probiotic. This may come as a surprise to many, but poha is a good probiotic food too. A Good Source of Healthy Carbohydrates. Easily Digestible. It has Rich In Iron. It has Low In Calories.",
      ingredient:
        "Rice flakes (61%), Refined Sunflower oil (9%), Peanut (9%), Dried vegetables (onion, ginger, green chillies and lemon powder) (6.0%), sugar (6%) whole spicies (cumin, mustard) (4.0%), Iodised Salt(3%), Compounded Asafoetida (1%), Powdered Spices (turmeric) (0.9%) and Curry leaves.(0.1%).",
      image: pohaImg,
    },
    {
      productId: "16",
      productName: "Upma",
      category: "Frreze Dried",
      netWeight: "70 gm",
      rehydrateWeight: "220 gm",
      description:
        "A bowl of upma has fibre, vitamins, and healthy fats. It is low in cholesterol and calories which makes it a healthy meal and helps you have a balanced diet. Upma is prepared using semolina and it is full of iron.",
      ingredient:
        "Suji (66%), Dried vegetables (onion,carrot,green pea, ginger and green chillies) (9.5%), peanut (6%), Refined Sunflower oil (5%), ghee (5%), Iodised Salt(3%), cashewnut (3%), udad dal (2%), sugar (1%) and Curry leaves (0.5%)",
      image: upmaImg,
    },
    {
      productId: "17",
      productName: "Pav Bhaji",
      category: "Frreze Dried",
      netWeight: "65 gm",
      rehydrateWeight: "265 gm",
      description:
        "This meal is a popular Indian street cuisine that can be whipped up and enjoyed in minutes! Frixty’s Ready-To-Eat Pav Bhaji is a potato-based dish with spiced mashed veggies. Ravishingly delicious, you can easily indulge in this rich delight at home right now!",
      ingredient:
        "Potato (26.04%), Tomato (26.04%), Butter (11.72%) Onion (9.11%), Capsicum (7.81%), Giner, Garlic, Peas, Salt, Coriander, Spices and Condiments.",
      image: pavBhajiImg,
    },
    {
      productId: "18",
      productName: "Gajar Halwa",
      category: "Frreze Dried",
      netWeight: "80 gm",
      rehydrateWeight: "180 gm",
      description: "",
      ingredient: "",
      image: gujarDalwaImg,
    },
    {
      productId: "19",
      productName: "Moong Dal Shira",
      category: "Frreze Dried",
      netWeight: "100 gm",
      rehydrateWeight: "150 gm",
      description: "",
      ingredient: "",
      image: moongDalShiraImg,
    },
    {
      productId: "20",
      productName: "Paneer Bhurji",
      category: "Frreze Dried",
      netWeight: "70 gm",
      rehydrateWeight: "250 gm",
      description: "",
      ingredient: "",
      image: paneerBhurjiImg,
    },
    {
      productId: "21",
      productName: "Veg Biryani",
      category: "Frreze Dried",
      netWeight: "70 gm",
      rehydrateWeight: "250 gm",
      description: "",
      ingredient: "",
      image: vegBiryaniImg,
    },
    {
      productId: "22",
      productName: "Schezwan Rice",
      category: "Frreze Dried",
      netWeight: "70 gm",
      rehydrateWeight: "250 gm",
      description: "",
      ingredient: "",
      image: schezwanRiceImg,
    },
    {
      productId: "23",
      productName: "Masala Kadhi Khichdi",
      category: "Frreze Dried",
      netWeight: "48 gm",
      rehydrateWeight: "248 gm",
      description: "",
      ingredient: "",
      image: masalaKadhiKhichadiImg,
    },
    {
      productId: "24",
      productName: "Tawa Pulao",
      category: "Frreze Dried",
      netWeight: "70 gm",
      rehydrateWeight: "250 gm",
      description: "",
      ingredient: "",
      image: tawaPulaoImg,
    },
    {
      productId: "25",
      productName: "Paneer Butter Masala",
      category: "Frreze Dried",
      netWeight: "75 gm",
      rehydrateWeight: "235 gm",
      description: "",
      ingredient: "",
      image: paneerButterMasalaImg,
    },
    {
      productId: "26",
      productName: "Dal Makhani",
      category: "Frreze Dried",
      netWeight: "80 gm",
      rehydrateWeight: "230 gm",
      description: "",
      ingredient: "",
      image: dalMakhaniImg,
    },
    {
      productId: "27",
      productName: "Palak Paneer",
      category: "Frreze Dried",
      netWeight: "70 gm",
      rehydrateWeight: "250 gm",
      description: "",
      ingredient: "",
      image: palakPaneerImg,
    },
  ]);

  const frixtySingleProductView = (props) => {
    console.log("frixtySingleProductView - props :: ", props);
    navigate("/product/frixty/view");
  };

  const [chocolatierDataState] = useState([
    {
      productId: "1",
      productName: "Fruit & Nuts",
      category: "Chocolates Bars",
      subCategory: "Belgiano",
      description: "Handcrafted signature blend chocolate fruits & nuts.",
      itemCode: "100/7700",
      weight: "53 gm",
      mrp: "110 /-",
      ingredient: "42% Choco",
      image: barFruitsNutsOne,
      imageTwo: barFruitsNutsTwo,
    },
    {
      productId: "2",
      productName: "Blend Chocolate",
      category: "Chocolates Bars",
      subCategory: "Belgiano",
      description: "Handcrafted signature blend chocolate.",
      itemCode: "100/7700",
      weight: "53 gm",
      mrp: "110 /-",
      ingredient: "42% Choco",
      image: barBlendedChocolateOne,
      imageTwo: barBlendedChocolateTwo,
      imageThree: barBlendedChocolateThree,
    },
    {
      productId: "3",
      productName: "Hazelnuts",
      category: "Chocolates Bars",
      subCategory: "Belgiano",
      description: "Handcrafted signature blend chocolate hazelnuts",
      itemCode: "100/7700",
      weight: "53 gm",
      mrp: "110 /-",
      ingredient: "42% Choco",
      image: barBlendChocolateHezalnutsOne,
      imageTwo: barBlendChocolateHezalnutsTwo,
    },
    {
      productId: "4",
      productName: "Cranberry",
      category: "Chocolates Bars",
      subCategory: "Belgiano",
      description: "Handcrafted signature blend chocolate Cranberry",
      itemCode: "100/7700",
      weight: "53 gm",
      mrp: "110 /-",
      ingredient: "42% Choco",
      image: barCranberryOne,
      imageTwo: barCranberryTwo,
      imageThree: barCranberryThree,
      imageFour: barCranberryFour,
    },
    {
      productId: "5",
      productName: "White Choco Roasted Almond & Cranberry",
      category: "Chocolates Bars",
      subCategory: "Belgiano",
      description: "Handcrafted White choco roasted almond & cranberry",
      itemCode: "100/7700",
      weight: "53 gm",
      mrp: "110 /-",
      ingredient: "42% Choco",
      image: barAlmodCranberryOne,
      imageTwo: barAlmodCranberryTwo,
    },
    {
      productId: "6",
      productName: "Roasted Almond",
      category: "Chocolates Bars",
      subCategory: "Belgiano",
      description: "Handcrafted signature blend chocolate Roasted Almond",
      itemCode: "102/9100",
      weight: "53 gm",
      mrp: "130 /-",
      ingredient: "55% Choco",
      image: barRoastedAlmondOne,
      imageTwo: barRoastedAlmondTwo,
      imageThree: barRoastedAlmondThree,
    },
    {
      productId: "7",
      productName: "Whole Almond",
      category: "Chocolates Bars",
      subCategory: "Belgiano",
      description:
        "Smooth handcrafted pure chocolate with 31% choco and whole roasted almond",
      itemCode: "1006/12600",
      weight: "88 gm",
      mrp: "180 /-",
      ingredient: "31% Choco",
      image: barWholeAlmond,
    },
    {
      productId: "8",
      productName: "Milk Chocolate",
      category: "Chocolates Bars",
      subCategory: "Classic Silky",
      description: "Handcrafted premium milk chocolate",
      itemCode: "101/7700",
      weight: "53 gm",
      mrp: "110 /-",
      ingredient: "31% Choco",
      image: barMilkChocolateOne,
      imageTwo: barMilkChocolateTwo,
      imageThree: barMilkChocolateThree,
    },
    {
      productId: "9",
      productName: "Milk Chocolate Roasted Almond",
      category: "Chocolates Bars",
      subCategory: "Classic Silky",
      description: "Handcrafted premium milk chocolate roasted almond",
      itemCode: "101/7700",
      weight: "53 gm",
      mrp: "110 /-",
      ingredient: "31% Choco",
      image: barMilkChocolateRostedAlomodOne,
      imageTwo: barMilkChocolateRostedAlomodTwo,
      imageThree: barMilkChocolateRostedAlomodThree,
    },
    {
      productId: "10",
      productName: "Almond Heart 3 Pieces Box",
      category: "Almond Heart",
      subCategory: "",
      description:
        "A Heavily teste of heart shape choco center filled with caramel and almond, cranberry and pistachio on the top, decorated with white choco simply lrresistible.",
      itemCode: "1002/7000",
      weight: "60 gm, 3 pieces of 20 gm",
      mrp: "100 /- ",
      ingredient: "",
      image: ahAlmondHeartThreePiecesBoxOne,
      imageTwo: ahAlmondHeartThreePiecesBoxTwo,
    },
    {
      productId: "11",
      productName: "Almond Heart",
      category: "Almond Heart",
      subCategory: "",
      description:
        "A Heavily teste of heart shape choco center filled with caramel and almond, brownie. Almond & cranberry on the top simply lrresistible.",
      itemCode: "1003/28700",
      weight: "240 gm",
      mrp: "410 /- (Pack of 12 pieces) 35 /- per pieces",
      ingredient: "",
      image: ahAlmondHeart,
    },
    {
      productId: "12",
      productName: "Almond Heart Lava",
      category: "Almond Heart",
      subCategory: "",
      description:
        "A Heavily teste of heart shape choco center filled with caramel and almond, brownie. Almond & cranberry on the top simply lrresistible.",
      itemCode: "1003/28700",
      weight: "240 gm",
      mrp: "410 /- (Pack of 12 pieces) 35 /- per pieces",
      ingredient: "",
      image: ahAlmondHeartLava,
    },
    {
      productId: "13",
      productName: "White & Golden Box",
      category: "Rich Assorted Collections",
      subCategory: "",
      description:
        "A combination of different flavors like classic plain, Butter scotch, Orange & Mix dryfruit to make your daya.",
      itemCode: "112/11200",
      weight: "110 gm",
      mrp: "160 /-",
      ingredient: "",
      image: racWhiteGoldenBoxOne,
      imageTwo: racWhiteGoldenBoxTwo,
    },
    {
      productId: "14",
      productName: "Heart",
      category: "Rich Assorted Collections",
      subCategory: "",
      description:
        "A combination of different flavors like classic plain, Butter scotch, Orange & Mix dryfruit to make your daya.",
      itemCode: "112/11200",
      weight: "90 gm",
      mrp: "180 /-",
      ingredient: "",
      image: racHeartOne,
      imageTwo: racHeartTwo,
      imageThree: racHeartThree,
    },
    {
      productId: "15",
      productName: "Red Heart Purse",
      category: "Rich Day Fruit Collections",
      subCategory: "",
      description:
        "Semi pure choco with twist of coffee flavor and roasted almond in each pieces. and mix dry fruit in rich dry fruit category.",
      itemCode: "206/10500",
      weight: "75 gm",
      mrp: "150 /-",
      ingredient: "",
      image: rdfcRedHeartPurseOne,
      imageTwo: rdfcRedHeartPurseTwo,
    },
    {
      productId: "16",
      productName: "Silky Family Pack",
      category: "Classic Silky",
      subCategory: "",
      description:
        "Silky smooth handcradted pure milk chocolate with 31% choco made with finest ghana choco is simply lrresistible.",
      itemCode: "309/21000",
      weight: "175 gm (20 Pieces)",
      mrp: "300 /-",
      ingredient: "",
      image: csSilkyFamilyPackOne,
      imageTwo: csSilkyFamilyPackTwo,
      imageThree: csSilkyFamilyPackThree,
    },
    {
      productId: "17",
      productName: "Car",
      category: "Customized Gifts Box",
      subCategory: "Belgiano",
      description:
        "Handcrafted signature blended pure chocolate with 42% choco made from finest european chocolate is one of our most demanding creation.",
      itemCode: "603/9100",
      weight: "60 gm",
      mrp: "130 /-",
      ingredient: "",
      image: cgbCarOne,
      imageTwo: cgbCarTwo,
    },
    {
      productId: "18",
      productName: "House",
      category: "Customized Gifts Box",
      subCategory: "Belgiano",
      description:
        "Handcrafted signature blended pure chocolate with 42% choco made from finest european chocolate is one of our most demanding creation.",
      itemCode: "605/11900",
      weight: "80 gm",
      mrp: "170 /-",
      ingredient: "",
      image: cgbHouseOne,
      imageTwo: cgbHouseTwo,
    },
    {
      productId: "19",
      productName: "Piper Box",
      category: "Customized Gifts Box",
      subCategory: "Belgiano",
      description:
        "Handcrafted signature blended pure chocolate with 42% choco made from finest european chocolate is one of our most demanding creation.",
      itemCode: "604/16100",
      weight: "110 gm",
      mrp: "130 /-",
      ingredient: "",
      image: cgbPiperBox,
    },
    {
      productId: "20",
      productName: "Square Box",
      category: "Truffles",
      subCategory: "",
      description: "Mango, Dark, Kulfi, Strawberry & Hazelnuts.",
      itemCode: "502/12600",
      weight: "150 gm",
      mrp: "180 /-",
      ingredient: "",
      image: trufflesSquareBoxOne,
      imageTwo: trufflesSquareBoxTwo,
    },
    {
      productId: "21",
      productName: "Round Box",
      category: "Truffles",
      subCategory: "",
      description: "Mango, Dark, Kulfi, Strawberry & Hazelnuts.",
      itemCode: "506/17500",
      weight: "195 gm",
      mrp: "250 /-",
      ingredient: "",
      image: trufflesRoundBoxOne,
      imageTwo: trufflesRoundBoxTwo,
    },
    {
      productId: "22",
      productName: "Rectangle Box",
      category: "Truffles",
      subCategory: "",
      description: "Mango, Dark, Kulfi, Strawberry & Hazelnuts.",
      itemCode: "503/8050",
      weight: "115 gm",
      mrp: "115 /-",
      ingredient: "",
      image: trufflesRectangleBoxOne,
      imageTwo: trufflesRectangleBoxTwo,
    },
    {
      productId: "23",
      productName: "Pouch",
      category: "Truffles",
      subCategory: "",
      description: "Mango, Dark, Kulfi, Strawberry & Hazelnuts.",
      itemCode: "504/21000",
      weight: "495 gm",
      mrp: "300 /-",
      ingredient: "",
      image: trufflesPouch,
    },
    {
      productId: "24",
      productName: "Black & Golden Bouquet",
      category: "Chocolate Bouquet",
      subCategory: "",
      description: "",
      itemCode: "712/42000",
      weight: "140 gm",
      mrp: "600 /-",
      ingredient: "",
      image: cbBlackGoldenbBouquetOne,
      imageTwo: cbBlackGoldenbBouquetTwo,
    },
    {
      productId: "25",
      productName: "Chocolate Bouquet",
      category: "Chocolate Bouquet",
      subCategory: "",
      description:
        "Belgiano mini bar, Belgiano bar, Classic silky, loose & Roasted Almond bar",
      itemCode: "704/42000",
      weight: "235 gm",
      mrp: "600 /-",
      ingredient:
        "Belgiano mini bar (42% choco) 2 pieces, Belgiano bar (42 % 1 pieces), Classic silky (31% choco), loose & Roasted Almond bar 1 pieces",
      image: cbChocolateBouquetOne,
      imageTwo: cbChocolateBouquetTwo,
    },
    {
      productId: "26",
      productName: "Yellow Jali Box",
      category: "Celebration Box",
      subCategory: "",
      description:
        "Almond, cashew & pistachio, Resin, Assorted chocolate. Box size - 8.00 x 8.00 x 1.25",
      itemCode: "3/25200",
      weight: "215 gm",
      mrp: "360 /-",
      ingredient:
        "Almond, cashew & pistachio 50 gm, Resin 60 gm, Assorted chocolate 105 gm.",
      image: celebrationYellowJeliBox,
    },
    {
      productId: "27",
      productName: "Emerald Box",
      category: "Celebration Box",
      subCategory: "",
      description:
        "Almond, cashew & pistachio, Resin, Assorted choco, Classic silky (31 % choco) chocolate, Belgiano (42 % choco) & white choco with almond. Box size - 10.50 x 6.25 x 1.10",
      itemCode: "17/80500",
      weight: "425 gm",
      mrp: "1150 /-",
      ingredient:
        "Almond, cashew & pistachio 80 gm, Resin 90 gm, Assorted choco 105 gm, Classic silky (31 % choco) chocolate 50 gm, Belgiano (42 % choco) & white choco with almond 100 gm.",
      image: celebrationEmeraldBox,
    },
    {
      productId: "28",
      productName: "2 Jar Hamper",
      category: "Celebration Box",
      subCategory: "",
      description:
        "Almond, cashew & pistachio 80 gm, Resin 60 gm, Belgiano (42 % choco) chocolate 95 gm. Box size - 9.50 x 5.00 x 5.00",
      itemCode: "20/60900",
      weight: "235 gm",
      mrp: "870 /-",
      ingredient:
        "Almond, cashew & pistachio 80 gm, Resin 60 gm, Belgiano (42 % choco) chocolate 95 gm.",
      image: celebrationTwoJarHamper,
    },
    {
      productId: "29",
      productName: "3 Jar Hamper",
      category: "Celebration Box",
      subCategory: "",
      description:
        "Almond, cashew & pistachio, Resin, Belgiano (42 % choco) chocolate, choco almonds balls. Box size 13.50 x 5.00 x 5.00",
      itemCode: "22/80500",
      weight: "340 gm",
      mrp: "1150 /-",
      ingredient:
        "Almond, cashew & pistachio 80 gm, Resin 60 gm, Belgiano (42 % choco) chocolate 95 gm. Choco Almond Balls",
      image: celebrationThreeJarHamper,
    },
    {
      productId: "30",
      productName: "Royal White Box",
      category: "Celebration Box",
      subCategory: "",
      description:
        "Almond, cashew & pistachio, Resin, Choco almond florantine, Choco almond balls, Belgiano (42% choco) chocolate, Nutty chocolate coated dryfruit, Dry Fruit choco, And Classic silky (31% choco) chocolate. Box size - 15.10 x 10.10 x 3.50.",
      itemCode: "26/136500",
      weight: "570 gm",
      mrp: "1950 /-",
      ingredient:
        "Almond, cashew & pistachio 60gm. Resin 70 gm. Choco almond florantine 75 gm. Choco almond balls 95 gm. Belgiano (42% choco) chocolate 65 gm. Nutty chocolate coated dryfruit 130 gm. Dry Fruit choco 25 gm. Classic silky (31% choco) chocolate 50 gm.",
      image: celebrationRoyalWhiteBoxOne,
      imageTwo: celebrationRoyalWhiteBoxTwo,
    },
  ]);

  const [chhaganMaganProductListState] = useState([
    {
      productId: "1",
      productName: "Salted Chips",
      category: "Potato Chips",
      description:
        "Test the quality products. Potato chips with salt Chhagan Magan's Salted Potato Chips will get even more charm with the ideal.",
      Weight: ["200 gm", "500 gm"],
      variety: ["Salted Chips", "Masala Chips"],
      image: chipsSaltedPatatoImg,
    },
    {
      productId: "2",
      productName: "Masala Chips",
      category: "Potato Chips",
      description:
        "Each of us has a different approach of showing our love. Giving a pack of Chhagan Magan's Chilli Masala Potato to your loved ones is the perfect way to share love! a new spice that has never been tried: Delicious Masala Chips The majority of the time, we pick potato chips as a snack or side dish.",
      Weight: ["200 gm", "500 gm"],
      variety: ["Salted Chips", "Masala Chips"],
      image: chipsMasalaPotatoImg,
    },
    {
      productId: "3",
      productName: "Tikha Mitha Chevda",
      category: "Namkeen",
      description:
        "Try out our Namkeen products; they are always fresh and restful. Open our chhagan magan Tikha Mitha Chevda and eat it.",
      Weight: ["200 gm"],
      variety: [
        "Tikha Mitha Chevda",
        "Khata Mitha Chevda",
        "Punjabi Mix",
        "Garlic Bhel",
        "Makai Pauva",
        "Tikhi Fafdi",
        "Moli Fafdi",
        "Golden Sweet Chevda",
        "Golden Spicy Chevda",
        "Regular Sev",
        "Pinapple Chevda",
        "Phudina Chevda",
        "Aaloo Save",
        "Bhavnagari Gathiya",
        "Naylon Sev",
        "Tikhi Bhel",
        "Sweet Farari Chevda",
        "Spicy Farari Chevda",
        "Papad Pauva",
      ],
      available: ["500 gm"],
      image: namkeenTikhaMithaChevdaImg,
    },
    {
      productId: "4",
      productName: "Khata Mitha Chevda",
      category: "Namkeen",
      description:
        "Try out our Namkeen products; they are always fresh and restful. Open our chhagan magan khatta mitha chevda and eat it. ",
      Weight: ["200 gm"],
      variety: [
        "Tikha Mitha Chevda",
        "Khata Mitha Chevda",
        "Punjabi Mix",
        "Garlic Bhel",
        "Makai Pauva",
        "Tikhi Fafdi",
        "Moli Fafdi",
        "Golden Sweet Chevda",
        "Golden Spicy Chevda",
        "Regular Sev",
        "Pinapple Chevda",
        "Phudina Chevda",
        "Aaloo Save",
        "Bhavnagari Gathiya",
        "Naylon Sev",
        "Tikhi Bhel",
        "Sweet Farari Chevda",
        "Spicy Farari Chevda",
        "Papad Pauva",
      ],
      available: ["500 gm"],
      image: namkeenKhataMithaChevdaImg,
    },
    {
      productId: "5",
      productName: "Soya",
      category: "Soya Stick",
      description:
        "With this variation of Chhagan Magan's original recipe, you will discover the deliciousness of chatpata, crispy sticks of protein-rich soya. These crisps are healthy and substantial.",
      Weight: ["200 gm"],
      variety: ["Soya", "Panipuri", "Machuriyan", "Tomato"],
      image: soyaStickImg,
    },
    {
      productId: "6",
      productName: "Plain Bhakhri",
      category: "Bhakhari",
      description:
        "Healthy & Tasty Companion of Your Journey !! A Perfect Travel Companion - Yes!! You heard it right, now no compromise with your health. Enjoy our Classic Gujarati Dry Bhakhri with two types of Godkeri Pickle while you are on Vacation Trip with wonders for delicious flavors to pick from.",
      Weight: ["200 gm"],
      variety: [
        "Methi Bhakhri",
        "Masala Bhakhri",
        "Jeera Bhakhri",
        "Garlic Bhakhri",
        "Plain Bhakhri",
        "Kothmir Bhakhri",
      ],
      image: bhakhriImg,
    },
    {
      productId: "7",
      productName: "Masala Khakhra",
      category: "Khakhra",
      description:
        "A traditional Gujarati snack made from wheat flour, khakhra, is the most loved snack by all. They are a crunchy snack and a healthy option for a balanced diet.",
      Weight: ["200 gm"],
      variety: [
        "Masala Khakhra",
        "Methi Khakhra",
        "Jeera Khakhra",
        "Plain Khakhra",
        "Machuriyan Khakhra",
        "Palak Khakhra",
        "Panipuri Khakhra",
        "Mag Khakhra",
      ],
      image: masalaKhakharaImg,
    },
    {
      productId: "8",
      productName: "Salted French Fried",
      category: "French Fried",
      description:
        "Our crisply cooked, salted- and masala-flavored French fries will brighten your day and add some crunch.",
      Weight: ["200 gm"],
      variety: ["Salted French Fried", "Masala Potato Chipps"],
      image: frenchFriesImg,
    },
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const val = useMemo(
    () => ({
      loading: loading,
      disabledField: disabledFormField,
      navigateToErr: navigateToError,
      contactDets: contactDetails,
      contactUsFormInitVal: contactUsFormInitialValues,
      contactUsFormVlidation: contactUsFormVlidation,
      handleContactUs: handleContactUsForm,
      snackbarState: snackbarState,
      closeSnakebar: handleSnakebarClose,
      carouselProductsList: carouselProductsState,
      frixtyProductList: frixtyProductListState,
      frixtySingleProduct: frixtySingleProductView,
      chocolatierProductList: chocolatierDataState,
      chhaganMaganProductList: chhaganMaganProductListState,
    }),
    [render]
  );

  return <MyContext.Provider value={val}>{children}</MyContext.Provider>;
};

export { MyContext, MyProvider };
