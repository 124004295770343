import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import {
  Chip,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useContext, useState } from "react";
import { MyContext } from "../context/context";
import {
  Grid,
  PageNavbar,
  ProductCard,
  ProductCardContent,
  ProductCardMedia,
  ProductCardSubTitle,
  ProductCardTitle,
  SearchBar,
  SearchInput,
  SeeAllProducts,
} from "../style/global.style";
import {
  ErrorMessageWrap,
  ErrorWrapTwo,
  ProductDetailsWrap,
  ProductFLexChip,
  ProductItem,
  ProductTitles,
  ProductViewWrap,
  Section,
} from "../style/page.style";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { isEmpty, lowerCase } from "lodash";
import { NoProductFound } from "../components/Image";

const ChhaganMaganHome = () => {
  const navigate = useNavigate();
  const { chhaganMaganProductList } = useContext(MyContext);

  const chhaganMaganHomeProduct = chhaganMaganProductList.slice(0, 3);

  return (
    <Section className="container">
      <Typography variant="h2" data-aos="zoom-in">
        Snacks
      </Typography>
      <Grid item="3" gap="1">
        {chhaganMaganHomeProduct.map((item, index) => {
          const { productId, productName, category, image } = item;
          return (
            <Link
              key={productId}
              data-aos="flip-left"
              to={`/product/chhagan-magan/view?productId=${productId}`}
            >
              <ProductCard variant="outlined">
                <ProductCardMedia
                  component="img"
                  image={image}
                  sx={{
                    backgroundColor: "white",
                  }}
                />
                <ProductCardContent>
                  <ProductCardTitle
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "1",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {productName}
                  </ProductCardTitle>
                  <ProductCardSubTitle>{category}</ProductCardSubTitle>
                </ProductCardContent>
              </ProductCard>
            </Link>
          );
        })}
      </Grid>
      <SeeAllProducts
        variant="contained"
        disableElevation
        onClick={() => navigate("/product/chhagan-magan")}
      >
        See All
      </SeeAllProducts>
    </Section>
  );
};

const ChhaganMaganProductsLists = () => {
  const { chhaganMaganProductList } = useContext(MyContext);

  const [searchInitVal, setSearchInitVal] = useState("");
  const searchValLen = searchInitVal.length;
  const finalProductList = chhaganMaganProductList.filter((i) => {
    return (
      lowerCase(i.productName).slice(0, searchValLen) ===
      lowerCase(searchInitVal)
    );
  });

  return (
    <Section className="container" pt="5">
      <PageNavbar elevation={0}>
        <SearchBar>
          <SearchInput
            placeholder="Search"
            inputProps={{ "aria-label": "search google maps" }}
            value={searchInitVal}
            onChange={(e) => setSearchInitVal(e.target.value)}
          />
          <IconButton type="button" aria-label="search">
            <SearchIcon />
          </IconButton>
          {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
        </SearchBar>
      </PageNavbar>
      {!isEmpty(finalProductList) ? (
        <>
          <Typography variant="h2" data-aos="zoom-in">
            Snacks
          </Typography>
          <Grid item="3" gap="1">
            {finalProductList.map((item, index) => {
              const { productId, productName, category, image } = item;
              return (
                <Link
                  key={productId}
                  data-aos="flip-left"
                  to={`view?productId=${productId}`}
                >
                  <ProductCard variant="outlined">
                    <ProductCardMedia
                      component="img"
                      image={image}
                      sx={{
                        backgroundColor: "white",
                      }}
                    />
                    <ProductCardContent>
                      <ProductCardTitle
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: "1",
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {productName}
                      </ProductCardTitle>
                      <ProductCardSubTitle>{category}</ProductCardSubTitle>
                      {/* <ProductCardDescription
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {description}
                  </ProductCardDescription> */}
                    </ProductCardContent>
                  </ProductCard>
                </Link>
              );
            })}
          </Grid>
        </>
      ) : (
        <ErrorWrapTwo>
          <NoProductFound />
          <ErrorMessageWrap>
            <Typography variant="h3" data-aos="zoom-in">
              Oops !!
            </Typography>
            <Typography variant="h6" data-aos="zoom-in">
              Your search result was not found.
            </Typography>
          </ErrorMessageWrap>
        </ErrorWrapTwo>
      )}
    </Section>
  );
};

const ChhaganMaganProductView = () => {
  const { chhaganMaganProductList } = useContext(MyContext);
  const [param] = useSearchParams();
  const productId = param.get("productId") - 1;

  const product = chhaganMaganProductList[productId];

  return (
    <ProductViewWrap className="container">
      <ProductItem>
        <img src={product.image} alt="Hero" data-aos="zoom-in" />
      </ProductItem>
      <ProductItem>
        <ProductTitles>
          <Typography variant="h3" data-aos="zoom-in">
            {product.productName}
          </Typography>
          <Typography variant="h6" data-aos="zoom-in">
            {product.category}
          </Typography>
        </ProductTitles>
        <Divider data-aos="zoom-in" />
        <ProductDetailsWrap>
          <Typography variant="h5" data-aos="zoom-in">
            Description
          </Typography>
          <Typography variant="body1" data-aos="zoom-in">
            {product.description}
          </Typography>
        </ProductDetailsWrap>
        <ProductDetailsWrap>
          <Typography variant="h5" data-aos="zoom-in">
            Available in
          </Typography>
          <ProductFLexChip>
            {product.Weight.map((item, i) => (
              <Chip
                key={i}
                label={item}
                color="secondary"
                variant="outlined"
                data-aos="zoom-in"
              />
            ))}
          </ProductFLexChip>
        </ProductDetailsWrap>
        <ProductDetailsWrap>
          <Typography variant="h5" data-aos="zoom-in">
            Variety
          </Typography>
          <Grid item="2">
            {product.variety.map((item, i) => (
              <ListItem key={i} data-aos="zoom-in">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </Grid>
        </ProductDetailsWrap>
      </ProductItem>
    </ProductViewWrap>
  );
};

export { ChhaganMaganHome, ChhaganMaganProductsLists, ChhaganMaganProductView };
