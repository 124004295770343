import {
  HeroContent,
  HeroItem,
  HeroTitles,
  HeroWrap,
} from "../style/page.style";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";

import { Typography } from "@mui/material";
import { useContext } from "react";
import { MyContext } from "../context/context";

const Hero = () => {
  const { carouselProductsList } = useContext(MyContext);
  return (
    <HeroWrap>
      <Swiper
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Navigation]}
      >
        {carouselProductsList.map((item) => {
          const {
            productId,
            productName,
            category,
            subCategory,
            description,
            image,
          } = item;
          return (
            <SwiperSlide key={productId}>
              <HeroContent>
                <img src={image} data-aos="zoom-in" alt="products" />
                <HeroItem>
                  <HeroTitles>
                    <Typography variant="h1" data-aos="zoom-in">
                      {productName}
                    </Typography>
                    <span className="title-flex">
                      <Typography variant="h5" data-aos="zoom-in">
                        {category} -
                      </Typography>
                      <Typography variant="h6" data-aos="zoom-in">
                        {subCategory}
                      </Typography>
                    </span>
                  </HeroTitles>
                  <Typography data-aos="zoom-in">{description}</Typography>
                </HeroItem>
              </HeroContent>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </HeroWrap>
  );
};

export default Hero;
