import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  createTheme,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const Theme = createTheme({
  typography: {
    fontFamily: "Medium",
    // fontSize: "1rem",
  },
  shape: { borderRadius: "0.6rem" },
  palette: {
    primary: {
      main: "#222831",
      light: "#4a505a",
      dark: "#000009",
      contrastText: "#fff",
    },
    secondary: {
      main: "#d65a31",
      light: "#ff8a5d",
      dark: "#9e2a04",
      contrastText: "#ffffff",
    },
  },
});

const Grid = styled(Box)`
  display: grid;
  gap: ${({ gap }) => `${gap}rem`};
  grid-template-columns: ${({ item }) => `repeat(${item}, 1fr)`};

  & > img {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`;

const ProductCard = styled(Card)`
  transition: ease-in 0.2s !important;
  height: 100%;

  &:hover {
    background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
    transform: scale(1.02);

    & > div > p {
      color: white;
    }
  }
`;

const ProductGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ gap }) => `${gap}rem`};
`;

const ProductContent = styled.div`
  position: relative;
  display: flex;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

  & > h4 {
    font-size: 1.5rem;
    text-align: center;
    writing-mode: vertical-rl;
    text-orientation: upright;
    /* background-color: transparent; */
    background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
    padding: 0.2rem;
    letter-spacing: -5px;
    border-radius: 4px;
  }

  & > img {
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    border-radius: 0 10px 10px 0;
  }
`;

const ProductTitle = styled(Typography)`
  color: red;
`;

const ProductCardMedia = styled(CardMedia)`
  min-height: 360px;
  max-height: 380px;
  object-fit: fit;
  padding: 1rem;
`;

const ProductCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const ProductCardTitle = styled(Typography)`
  font-size: 1.8rem;
  font-family: "semiBold";
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const ProductCardSubTitle = styled(Typography)`
  font-size: 1.18rem;
  color: ${({ theme }) => theme.palette.primary.dark};
`;
const ProductCardDescription = styled(Typography)`
  font-size: 1.04rem;
  color: ${({ theme }) => theme.palette.primary.light};
  padding-top: 1rem;
`;

const SeeAllProducts = styled(Button)`
  background: linear-gradient(to bottom, #323232 0%, #3f3f3f 40%, #1c1c1c 150%),
    linear-gradient(
      to top,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(0, 0, 0, 0.25) 200%
    );
  background-blend-mode: multiply;
  padding-inline: 1.5rem;
`;

const PageNavbar = styled(Paper)`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding: 0.5rem;
`;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;

  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;
const SearchInput = styled(InputBase)`
  padding: 0.2rem 0.8rem;
`;

const IconLink = styled(Link)`
  display: flex;
  gap: 0.4rem;
  align-items: center;
`;

const Header = styled.header`
  width: 100vw;
  position: fixed;
  z-index: 99;
  background-color: ${({ bg }) => bg && "rgba(255, 255, 255, 20%)"};
  backdrop-filter: ${({ bg }) => bg && "saturate(180%) blur(20px)"};
  transition: ease-in 0.3s;
  top: 0;
`;

const Main = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4rem;

  @media only screen and (max-width: 500px) {
    gap: 2rem;
  }
`;

const FooterWrap = styled.footer`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.primary.main};
  /* background-image: linear-gradient(to right, #434343 0%, black 100%); */
  color: ${({ theme }) => theme.palette.primary.contrastText};
  margin-top: 4rem;
  /* padding: 4rem 0; */
  border-radius: 1rem 1rem 0 0;

  .contact-icon {
    max-width: 1.2rem;
  }

  @media only screen and (max-width: 500px) {
    margin-top: 2rem;
  }
`;
const FooterItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > p {
    text-align: justify;
  }

  & > h6 {
    position: relative;
  }
  & > h6::after {
    content: "";
    display: block;
    position: absolute;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    width: 50px;
    height: 0.2rem;
    border-radius: 50px;
  }
`;
const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & > a {
    font-family: "Medium";
    color: ${({ theme }) => theme.palette.primary.contrastText};
    transition: 0.4s;
  }
  & a:hover {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const FooterCopyright = styled.div`
  background-image: linear-gradient(-60deg, #ff5858 0%, #f09819 100%);
  padding: 0.5rem;
  border-radius: 1rem 1rem 0 0;

  .footer-logo {
    max-width: 160px;
    padding: 0.5rem 0;
  }
  @media only screen and (max-width: 500px) {
    padding: 1.5rem 0;
  }
`;

const FooterCopyrightItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.6rem;
  }
`;

const DevelopmentCredit = styled.div`
  color: white;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-family: sans-serif;

  .development-credit-link {
    font-family: "SemiBold";
    position: relative !important;
    color: inherit;
    padding-inline: 0.1rem;
    transition: 0.5s ease-in-out;

    &:hover {
      color: black;
    }
  }
`;

export {
  Theme,
  Grid,
  ProductGrid,
  ProductTitle,
  ProductContent,
  ProductCard,
  ProductCardMedia,
  ProductCardContent,
  ProductCardTitle,
  ProductCardSubTitle,
  ProductCardDescription,
  SeeAllProducts,
  PageNavbar,
  SearchBar,
  SearchInput,
  IconLink,
  Header,
  Main,
  FooterWrap,
  FooterItem,
  FooterLinks,
  FooterCopyright,
  FooterCopyrightItem,
  DevelopmentCredit,
};
