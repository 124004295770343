import { ThemeProvider } from "@emotion/react";
import "./style/font/font.style.css";
import { Header, Main, Theme } from "./style/global.style";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home.page";
import { Route, Routes } from "react-router-dom";
import ContactUs from "./pages/ContactUs";
import { AboutUs } from "./pages/AboutUs.page";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Error from "./pages/Error.page";
import {
  ReadyToEatProductsLists,
  ReadyToEatProductView,
} from "./pages/ReadyToEat.page";
import {
  ChocolatierProductsLists,
  ChocolatierProductView,
} from "./pages/Chocolatier.page";
import {
  ChhaganMaganProductsLists,
  ChhaganMaganProductView,
} from "./pages/ChhaganMagan.page";
import { Product } from "./pages/Product.page";

const App = () => {
  const [showHeaderbackground, setShowHeaderbackground] = useState(false);
  const listenScrollEvent = () => {
    window.scrollY > 100
      ? setShowHeaderbackground(true)
      : setShowHeaderbackground(false);
  };

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);
  return (
    <ThemeProvider theme={Theme}>
      <Header bg={showHeaderbackground}>
        <Navbar />
      </Header>
      <Main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route
            path="/product/ready-to-eat"
            element={<ReadyToEatProductsLists />}
          />
          <Route
            path="/product/ready-to-eat/view"
            element={<ReadyToEatProductView />}
          />
          <Route
            path="/product/chocolatier"
            element={<ChocolatierProductsLists />}
          />
          <Route
            path="/product/chocolatier/view"
            element={<ChocolatierProductView />}
          />
          <Route
            path="/product/chhagan-magan"
            element={<ChhaganMaganProductsLists />}
          />
          <Route
            path="/product/chhagan-magan/view"
            element={<ChhaganMaganProductView />}
          />

          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/error" element={<Error />} />
        </Routes>
      </Main>
      <Footer />
    </ThemeProvider>
  );
};

export default App;
