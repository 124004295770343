import {
  Collapse,
  Divider,
  Drawer,
  IconButton,
  LinearProgress,
  List,
  ListItemButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { Nav, Sidebar } from "../style/navbar.style";
import { BlackLogo } from "./Image";
import { useContext, useState } from "react";
import { MyContext } from "../context/context";

const Navbar = () => {
  const { loading } = useContext(MyContext);
  const [activeSideBar, setActiveSideBar] = useState(false);
  const [activSubLink, setActivSubLink] = useState(true);

  const toggleSideBar = () => {
    setActiveSideBar(!activeSideBar);
  };

  const toggleSubLink = () => {
    setActivSubLink(!activSubLink);
  };

  const list = () => (
    <Sidebar>
      <span className="sidebar-close-btn">
        <IconButton size="small" onClick={toggleSideBar}>
          <CloseIcon />
        </IconButton>
      </span>
      <Divider />
      <Link className="sidebar-links" to="/" onClick={toggleSideBar}>
        <ListItemButton sx={{ p: 2 }}>Home</ListItemButton>
      </Link>

      <Divider />

      <span className="sidebar-flex" sx={{ p: 2 }}>
        <Link className="sidebar-links" to="/product" onClick={toggleSideBar}>
          Products
        </Link>
        {activSubLink ? (
          <ExpandLess color="secondary" onClick={toggleSubLink} />
        ) : (
          <ExpandMore color="secondary" onClick={toggleSubLink} />
        )}
      </span>

      <Collapse in={activSubLink} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={toggleSideBar}>
            <Link className="sidebar-links" to="/product/ready-to-eat">
              Ready To Eat
            </Link>
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={toggleSideBar}>
            <Link className="sidebar-links" to="/product/chocolatier">
              Chocolatier
            </Link>
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={toggleSideBar}>
            <Link className="sidebar-links" to="/product/chhagan-magan">
              Snacks
            </Link>
          </ListItemButton>
        </List>
      </Collapse>
      <Divider />
      <Link className="sidebar-links" to="/about-us" onClick={toggleSideBar}>
        <ListItemButton sx={{ p: 2 }}>About us</ListItemButton>
      </Link>

      <Divider />

      <Link className="sidebar-links" to="/contact-us" onClick={toggleSideBar}>
        <ListItemButton sx={{ p: 2 }}>Contact us</ListItemButton>
      </Link>
    </Sidebar>
  );

  return (
    <>
      <Nav className="container">
        <Link to="/">
          <BlackLogo style={`nav-logo`} />
        </Link>
        <IconButton size="small" onClick={toggleSideBar}>
          {!activeSideBar ? <MenuIcon /> : <CloseIcon />}
        </IconButton>
      </Nav>
      <Drawer anchor="right" open={activeSideBar} onClose={toggleSideBar}>
        {list("right")}
      </Drawer>
      {loading && <LinearProgress color="secondary" />}
    </>
  );
};

export default Navbar;
