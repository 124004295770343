import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import {
  DevelopmentCredit,
  FooterCopyright,
  FooterCopyrightItem,
  FooterItem,
  FooterLinks,
  FooterWrap,
  Grid,
  IconLink,
} from "../style/global.style";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { ContactIcon, WhiteLogo } from "./Image";
import { useContext } from "react";
import { MyContext } from "../context/context";

const Footer = () => {
  const { contactDets } = useContext(MyContext);
  return (
    <FooterWrap>
      <Grid className="container" item="4" gap="2" sx={{ p: 1, pt: 8, pb: 8 }}>
        <FooterItem>
          <Typography variant="body1">
            Aspire Impex goes above and beyond merely providing the services you
            require. We are glad to assist you in making the best decision and
            delivering it to you.
          </Typography>
        </FooterItem>
        <FooterItem>
          <Typography variant="h6">Quick Links</Typography>
          <FooterLinks>
            <Link to="/">Home</Link>
            <Link to="/product">Products</Link>
            <Link to="/about-us">About Us</Link>
            <Link to="/contact-us">Contact Us</Link>
          </FooterLinks>
        </FooterItem>
        <FooterItem>
          <Typography variant="h6">Products</Typography>
          <FooterLinks>
            <Link to="/product/ready-to-eat">Ready To Eat</Link>
            <Link to="/product/chocolatier">Chocolatier</Link>
            <Link to="/product/chhagan-magan">Snacks</Link>
          </FooterLinks>
        </FooterItem>
        <FooterItem>
          <Typography variant="h6">Get in Touch</Typography>
          <FooterLinks>
            <IconLink>
              {/* <CallIcon fontSize="small" /> */}
              <ContactIcon style={`contact-icon`} />
              {contactDets.contactNo}
            </IconLink>
            <IconLink>
              <EmailIcon fontSize="small" />
              {contactDets.email}
            </IconLink>
            <IconLink>
              <LocationOnIcon fontSize="small" />
              {contactDets.address}
            </IconLink>
          </FooterLinks>
        </FooterItem>
      </Grid>
      <FooterCopyright>
        <FooterCopyrightItem className="container">
          <Link to="/">
            <WhiteLogo style={`footer-logo`} />
          </Link>
          <DevelopmentCredit>
            <Typography> Developed by</Typography>
            <a
              className="development-credit-link"
              href="https://www.linkedin.com/in/milan-jotva-097046203"
              target="_blank"
              rel="noreferrer"
            >
              Milan Jotva
            </a>
            <Typography>|</Typography>
            {/* <Divider orientation="vertical" flexItem /> */}
            <a
              className="development-credit-link"
              href="https://www.linkedin.com/in/sagar-dodiya"
              target="_blank"
              rel="noreferrer"
            >
              SD Rajput
            </a>
          </DevelopmentCredit>
        </FooterCopyrightItem>
      </FooterCopyright>
    </FooterWrap>
  );
};

export default Footer;
