import { ReadyToEatHome } from "./ReadyToEat.page";
import { ChocolatierHome } from "./Chocolatier.page";
import { ChhaganMaganHome } from "./ChhaganMagan.page";
import { Section } from "../style/page.style";

const Product = () => {
  return (
    <Section className="container" pt="3">
      <ReadyToEatHome />
      <ChocolatierHome />
      <ChhaganMaganHome />
    </Section>
  );
};

export { Product };
