import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import {
  DocumentationImg,
  GuideYouImg,
  OurMissionImg,
  OurVisionImg,
  PaymentPoliciesImg,
  QuickDeliveryImg,
  ValueForMoneyImg,
  WhyUsImg,
} from "../components/Image";
import { Grid } from "../style/global.style";
import {
  AboutItem,
  AboutUsCard,
  AboutUsContent,
  AboutUsWrap,
  Section,
  SectionButton,
  ServicesBox,
  WhyUsContent,
} from "../style/page.style";

const makeUsSpecialData = [
  {
    id: 1,
    title: "Guide you to your preference",
    desc: "At Aspire Impex, we strive beyond simply providing the services you need. We are happy to help you select the right option and deliver it to you. Helping your business grow fast.",
    img: <GuideYouImg />,
  },
  {
    id: 2,
    title: "Our mission",
    desc: "We strive to achieve superior customer satisfaction by providing products, services, and value that exceed customers' expectations. As the founder of our company, it is our vision to experience the world through the most comprehensive wave of international trade",
    img: <OurMissionImg />,
  },
  {
    id: 3,
    title: "Our vision",
    desc: "Our vision is to achieve sustainable growth and to become a global leader in all our service offerings. Become the first international customers' choice for a variety of products, and service excellence.",
    img: <OurVisionImg />,
  },
];

const ServicesData = [
  {
    id: 1,
    img: <ValueForMoneyImg />,
    title: "Value for money",
    desc: "We are able to deliver these services within the parameters of our organisation, ensuring our clients receive the highest value for money.",
  },
  {
    id: 2,
    img: <PaymentPoliciesImg />,
    title: "Flexible Business And Payment Policies",
    desc: "The executives at our company are always eager to understand the needs of your business. They will be ready to assist with customised solutions and flexible payment terms as well. In order to meet the needs of our clients, we provide them with services that are tailored to their specific needs.",
  },
  {
    id: 3,
    img: <QuickDeliveryImg />,
    title: "Long-Term Reliability And Quick Delivery",
    desc: "Supporting local manufacturers is the core of our business model. Having strategic relationships with producers across the globe allows us to source our products anywhere. By doing so, we are able to consistently and quickly fulfil our orders",
  },
  {
    id: 4,
    img: <DocumentationImg />,
    title: "Accurate documentation",
    desc: "By ensuring that your shipping paperwork is accurate, we ensure that none of your shipments will be delayed.",
  },
];

const AboutUsHome = () => {
  const navigate = useNavigate();
  return (
    <Section className="container">
      <Typography variant="h2" data-aos="zoom-in">
        What We Do
      </Typography>
      <Typography variant="h6" data-aos="zoom-in">
        Aspire Impex distributes premium, confectionery, and specialty foods
        globally. In addition to supplying excellent products and service at
        competitive prices, we are committed to providing an exceptional
        customer experience as well. Offering exceptional products that are
        sourced globally, packaged with your needs in mind, and delivered as
        soon as possible is what we are dedicated to. It is our pleasure to
        assist you with your procurement needs, and we will work diligently to
        earn and maintain your trust in us as an invaluable provider.
      </Typography>
      <SectionButton
        data-aos="zoom-in"
        variant="contained"
        disableElevation
        onClick={() => navigate("/about-us")}
      >
        Explore More
      </SectionButton>
    </Section>
  );
};

const AboutUs = () => {
  return (
    <AboutUsWrap className="container">
      <WhyUsContent>
        <AboutItem data-aos="zoom-in">
          <WhyUsImg />
        </AboutItem>
        <AboutItem data-aos="zoom-in">
          <Typography variant="h2">Who are we? Why us?</Typography>
          <Typography variant="body1">
            Founded in 2022, Aspire Impex distributes premium, confectionery,
            and specialty foods globally. In addition to supplying excellent
            products and service at competitive prices, we are committed to
            providing an exceptional customer experience as well. The team at
            Aspire Impex is dedicated to offering you a hassle-free experience
            as well as premium products. Offering exceptional products that are
            sourced globally, packaged with your needs in mind, and delivered as
            soon as possible is what we are dedicated to. It is our pleasure to
            assist you with your procurement needs, and we will work diligently
            to earn and maintain your trust in us as an invaluable provider.
          </Typography>
        </AboutItem>
      </WhyUsContent>
      <AboutUsContent>
        <Typography variant="h3">What makes us special ?</Typography>
        <Grid item="3" gap="1">
          {makeUsSpecialData.map((item) => {
            const { id, title, desc, img } = item;
            return (
              <AboutUsCard key={id} data-aos="zoom-in">
                {img}
                <Typography variant="h5">{title}</Typography>
                <Typography>{desc}</Typography>
              </AboutUsCard>
            );
          })}
        </Grid>
      </AboutUsContent>
      <AboutUsContent data-aos="zoom-in">
        <Typography variant="h3">Core Value</Typography>
        <span className="txt-center">
          <Typography>
            In addition to its relationships with suppliers and buyers Aspire
            Impex values its relationships with employees.
          </Typography>
          <Typography>
            Develop highly skilled, motivated, inspired and committed employees
          </Typography>
          <Typography>
            Enhance customer loyalty through excellent customer service
          </Typography>
        </span>
      </AboutUsContent>
      <AboutUsContent>
        <Typography variant="h3">Services</Typography>
        <Grid item="2">
          {ServicesData.map((item) => {
            const { id, title, desc, img } = item;
            return (
              <ServicesBox key={id} data-aos="zoom-in">
                {img}
                <Typography variant="h5">{title}</Typography>
                <Typography>{desc}</Typography>
              </ServicesBox>
            );
          })}
        </Grid>
      </AboutUsContent>
    </AboutUsWrap>
  );
};

export { AboutUsHome, AboutUs };
