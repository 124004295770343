import { useContext } from "react";
import { Alert, Button, Snackbar, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import { IconLink } from "../style/global.style";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  ContactForm,
  ContactInfo,
  ContactItem,
  ContactUsWrap,
} from "../style/page.style";
import { MyContext } from "../context/context";

const ContactUs = () => {
  const {
    contactDets,
    contactUsFormInitVal,
    contactUsFormVlidation,
    handleContactUs,
    disabledField,
    snackbarState,
    closeSnakebar,
  } = useContext(MyContext);

  return (
    <ContactUsWrap className="container">
      <ContactItem data-aos="zoom-in">
        <Typography variant="h3">Contact Info</Typography>
        <ContactInfo>
          <IconLink>
            <CallIcon fontSize="small" />
            {contactDets.contactNo}
          </IconLink>
          <IconLink>
            <EmailIcon fontSize="small" />
            {contactDets.email}
          </IconLink>
          <IconLink>
            <LocationOnIcon fontSize="small" />
            {contactDets.address}
          </IconLink>
        </ContactInfo>
      </ContactItem>
      <ContactItem data-aos="zoom-in">
        <Typography variant="h3">Contact Us</Typography>
        <Formik
          initialValues={contactUsFormInitVal}
          validationSchema={contactUsFormVlidation}
          onSubmit={(values, { resetForm }) =>
            handleContactUs({ values, resetForm })
          }
        >
          {(form) => {
            const { values, handleChange, touched, errors } = form;
            return (
              <ContactForm>
                <TextField
                  id="Firstname"
                  label="Firstname"
                  variant="standard"
                  name="firstname"
                  value={values.firstname}
                  onChange={handleChange}
                  disabled={disabledField}
                  error={touched.firstname && Boolean(errors.firstname)}
                  helperText={touched.firstname && errors.firstname}
                />
                <TextField
                  id="Lastname"
                  label="Lastname"
                  variant="standard"
                  name="lastname"
                  value={values.lastname}
                  onChange={handleChange}
                  disabled={disabledField}
                  error={touched.lastname && Boolean(errors.lastname)}
                  helperText={touched.lastname && errors.lastname}
                />
                <TextField
                  id="Email"
                  label="Email"
                  variant="standard"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  disabled={disabledField}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
                <TextField
                  id="Phone No."
                  label="Phone No."
                  variant="standard"
                  type="number"
                  name="phoneNo"
                  value={values.phoneNo}
                  onChange={handleChange}
                  disabled={disabledField}
                  error={touched.phoneNo && Boolean(errors.phoneNo)}
                  helperText={touched.phoneNo && errors.phoneNo}
                />
                <TextField
                  className="col-span"
                  id="Message"
                  label="Message"
                  multiline
                  rows={4}
                  variant="standard"
                  fullWidth
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  disabled={disabledField}
                  error={touched.message && Boolean(errors.message)}
                  helperText={touched.message && errors.message}
                />
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disableElevation
                  disabled={disabledField}
                >
                  Submit
                </Button>
              </ContactForm>
            );
          }}
        </Formik>
      </ContactItem>
      <Snackbar
        open={snackbarState}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3500}
        onClose={closeSnakebar}
      >
        <Alert variant="filled" severity="success">
          The email was sent successfully.
        </Alert>
      </Snackbar>
    </ContactUsWrap>
  );
};

export default ContactUs;
